import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/forms/Input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import nProgress from "nprogress";
import { toast } from 'react-hot-toast';
import axios from 'axios';


const CreatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [userClaims, setUserClaims] = useState(null);

  const schema = Yup.object({
    username: Yup.string().required("Email is required").email("Invalid email address"),
    password: Yup.string()
    .required("Password is Required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])/,
      "Password must contain at least one lowercase letter"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter"
    )
    .matches(/^(?=.*[!@#$%^&*_-])/, "Must Contain One Special Character"),
});
useEffect(() => {
  const fetchUserClaims = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}provider/extract/claims`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        console.log('User Claims:', response.data);
        setUserClaims(response.data);
      }
    } catch (error) {
      console.error('Error fetching user claims:', error.response?.data?.message || error.message);
    }
  };

  fetchUserClaims();
}, [token]);

  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (userClaims) {
      setInitialValues({
        username: userClaims.data.sub || "",
        password: "",
      });
    }
  }, [userClaims]);

  const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit: async (values) => {
        try {
          setIsLoading(true);
          const response = await axios.put(`${process.env.REACT_APP_BASE_URL}provider/password/setup`, {
            password: values.password
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          if (response.status === 200) {
            toast.success("You have successfully setup your account");
            setIsLoading(false);
            navigate("/auth/login");
          }
        } catch (error) {
          toast.error(error.response?.data?.message || "An error occurred");
          setIsLoading(false);
        }
      },
    });


  useEffect(() => {
    if (isLoading) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [isLoading]);




  return (
    <div className="auth__form--content">
      <div className="auth__form--header">
        <div className="header__container">
          <div className="header__container--content">
            {userClaims && userClaims.data.firstName && userClaims.data.providerName && userClaims.data.lastName ? (
              <h3 className="tx-c">Hello <span>{userClaims.data.firstName} {userClaims.data.lastName},</span>  <br /> You've Been Invited to Join {userClaims.data.providerName}</h3>
            ) : (
              <h3 className="tx-c">Hello</h3>
            )}
            <p className="tx-c">Your company has assigned you a role. Set up your account to get started.</p> 
          </div>
        </div>
      </div>
      <form className="__form" onSubmit={handleSubmit}>
        <Input
          type="text"
          id="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Email Address"
          name="username"
          disabled={userClaims?.data?.sub ? true : false}
          error={
            touched.username && errors.username ? (
              <small className="error">{errors.username}</small>
            ) : null
          }
        />

        <Input
          type="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Create Password"
          name="password"
          error={
            touched.password && errors.password ? (
              <small className="error">{errors.password}</small>
            ) : null
          }
        />

        <Button
          variant="primary"
          type="submit"
          className="mt-10"
          block
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Set Up My Account"}
        </Button>
      </form>
      {/* <p className="32 tx-c extralinnk">
        {"You don't have a provider account?"}{" "}
        <Link className="btn btn--link btn--link-secondary" to="/auth/signup">
          Create an account
        </Link>
      </p> */}
    </div>
  );
};

export default CreatePassword;
