export const roleHierarchy = {
  OPERATOR: 1,
  BRANCH_MANAGER: 2,
  REGIONAL_MANAGER: 3,
  ADMIN: 4,
  SUPER_ADMIN: 5
};

/**
 * Checks if the current user role has permission based on the required role.
 * @param {string} currentRole - The current user's role.
 * @param {string} requiredRole - The required role for permission.
 * @returns {boolean} - True if the current role has permission, false otherwise.
 * 
 * Usage:
 * - Pass the current user's role as the first argument.
 * - Pass the required role as the second argument. This can be:
 *   - A specific role (e.g., 'ADMIN') returns true if the current role is the same as or higher in hierarchy than the required role.
 *   - A role preceded by '<' (e.g., '<ADMIN') returns true if the current role is lower than the required role.
 *   - A role preceded by '>' (e.g., '>OPERATOR') returns true if the current role is higher than the required role.
 *   - A role preceded by '!' (e.g., '!BRANCH_MANAGER') returns true if the current role is not the required role.
 *   - A role preceded by '=' (e.g., '=REGIONAL_MANAGER') returns true if the current role is the same as the required role.
 * 
 * Example usage:
 * hasPermission('ADMIN', 'SUPER_ADMIN') // Returns false
 * hasPermission('REGIONAL_MANAGER', '<ADMIN') // Returns true
 * hasPermission('BRANCH_MANAGER', '>OPERATOR') // Returns true
 * hasPermission('ADMIN', '!BRANCH_MANAGER') // Returns true
 * hasPermission('REGIONAL_MANAGER', '=REGIONAL_MANAGER') // Returns true
 */
export const hasPermission = (currentRole, requiredRole) => {
  console.log("Current Role", currentRole, "Required Role", requiredRole);
  if (!currentRole || !roleHierarchy[currentRole]) return false;
  if (typeof requiredRole === 'string') {
    if (requiredRole.startsWith('<')) {
      const roleToCompare = requiredRole.slice(1);
      return roleHierarchy[currentRole] < roleHierarchy[roleToCompare];
    } else if (requiredRole.startsWith('!')) {
      const roleToExclude = requiredRole.slice(1);
      return currentRole !== roleToExclude;
    } else if (requiredRole.startsWith('>')) {
      const roleToCompare = requiredRole.slice(1);
      return roleHierarchy[currentRole] > roleHierarchy[roleToCompare];
    }
    else if (requiredRole.startsWith('=')) {
      const roleToCompare = requiredRole.slice(1);
      return roleHierarchy[currentRole] === roleHierarchy[roleToCompare];
    }
  }
  return roleHierarchy[currentRole] >= roleHierarchy[requiredRole];
}; 