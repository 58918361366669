import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import toast from "react-hot-toast";
import { hasPermission } from "../../utils/authHelpers";

const initialState = {
  isLoading: false,
  isDeleting: false,
  clients: [],
  clientStatus: [],
  clientVisit: "",
  clientActivity: [],
  clientSearch: [],
  singleClientActivity: [],
};

export const getProviderSubscribers = createAsyncThunk(
  "user/getProviderSubscribers",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }

    const getFromLocalStorage = () => {
      try {
        const value = localStorage.getItem("branch");

        if (value === null || value === undefined || value === "undefined") {
          return null; // Return null if the key doesn't exist
        }
        return JSON.parse(value); // Parse the JSON value
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
        return null; // Return null if parsing fails
      }
    };
    const { id } = getFromLocalStorage();

    try {
      const resp = await customFetch.get(`provider/clients?branchId=${id}`);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getClientStatus = createAsyncThunk(
  "user/getClientStatus",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.get(
        `provider/clients/status?branchId=${payload?.branch}`
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getClientVisits = createAsyncThunk(
  "user/getClientVisits",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("usern", user);
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.get(
        `provider/flance-pass/visit/log/total/${payload?.branch}`
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getClientActivity = createAsyncThunk(
  "user/getClientActivity",
  async (currentPage, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.get(
        `provider/clients/activity?clientTypes=${currentPage?.clientType}&page=${currentPage?.page}&size=${currentPage?.numPerPage}&providerBranchId=${currentPage?.branch}`
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

export const sendClientRenewalMail = createAsyncThunk(
  "user/sendClientRenewalMail",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.post(
        "provider/clients/renew/email",
        payload
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const sendClientRenewalSms = createAsyncThunk(
  "user/sendClientRenewalSms",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.post(
        "provider/clients/renew/sms",
        payload
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const sendClientsRenewalMail = createAsyncThunk(
  "user/sendClientsRenewalMail",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.post(
        "provider/clients/renew/email/bulk",
        payload
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const sendClientsRenewalSms = createAsyncThunk(
  "user/sendClientsRenewalSms",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.post(
        "provider/clients/renew/sms/bulk",
        payload
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const renewalClient = createAsyncThunk(
  "user/renewalClient",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.post("provider/client/renew", payload, {
        headers: {
          "FL-IDEMPOTENT-KEY": `${payload?.providerId}${payload?.clientId}${payload?.benefitId}`,
        },
      });
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const searchClient = createAsyncThunk(
  "user/searchClient",
  async (name, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.get(
        `provider/clients/search?name=${name}`
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getSingleClientActivity = createAsyncThunk(
  "user/getSingleClientActivity",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "OPERATOR")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.get(
        `provider/clients/activity/${payload?.id}/${payload?.clientType}`
      );
      return resp?.data?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const deleteClient = createAsyncThunk(
  "user/deleteClient",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "ADMIN")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.delete(
        `provider/client?clientId=${payload?.id}&clientType=${payload?.clientType}&providerBranchId=${payload?.branchId}`
      );
      return resp?.data?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const editClientDetaails = createAsyncThunk(
  "user/editClientDetaails",
  async (payload, thunkAPI) => {
    const { user } = thunkAPI.getState();
    const userRole = user?.user?.data?.roleName;

    if (!hasPermission(userRole, "ADMIN")) {
      console.log("Permission denied");
      return;
    }
    try {
      const resp = await customFetch.put(`provider/clients/${payload?.id}`, {
        dateOfBirth: payload?.dob,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        phone: payload?.phone,
        callingCode: payload.callingCode,
        email: payload?.email,
      });
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProviderSubscribers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderSubscribers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clients = payload;
      })
      .addCase(getProviderSubscribers.rejected, (state, { payload }) => {
        if (payload?.isPermissionError) {
          return;
        }
        state.isLoading = false;
        state.errMsg = payload;
        payload?.message && toast.error(payload?.message);
      })
      .addCase(getClientStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientStatus = payload?.data;
      })
      .addCase(getClientStatus.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(getClientVisits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientVisits.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientVisit = payload?.data;
      })
      .addCase(getClientVisits.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(getClientActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientActivity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientActivity = payload?.data;
      })
      .addCase(getClientActivity.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(sendClientRenewalMail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendClientRenewalMail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload?.message && toast.success(payload?.message);
      })
      .addCase(sendClientRenewalMail.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(sendClientRenewalSms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendClientRenewalSms.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload?.message && toast.success(payload?.message);
      })
      .addCase(sendClientRenewalSms.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(sendClientsRenewalMail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendClientsRenewalMail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload?.message && toast.success(payload?.message);
      })
      .addCase(sendClientsRenewalMail.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(sendClientsRenewalSms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendClientsRenewalSms.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload?.message && toast.success(payload?.message);
      })
      .addCase(sendClientsRenewalSms.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(renewalClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(renewalClient.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload?.message && toast.success(payload?.message);
      })
      .addCase(renewalClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(searchClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchClient.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientSearch = payload?.data;
      })
      .addCase(searchClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(getSingleClientActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleClientActivity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.singleClientActivity = payload?.clientTransactions;
      })
      .addCase(getSingleClientActivity.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      })
      .addCase(deleteClient.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteClient.fulfilled, (state, { payload }) => {
        state.isDeleting = false;
        payload?.message && toast.success(payload?.message);
      })
      .addCase(deleteClient.rejected, (state, { payload }) => {
        state.isDeleting = false;
        payload && toast.error(payload);
      })
      .addCase(editClientDetaails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editClientDetaails.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(editClientDetaails.rejected, (state, { payload }) => {
        state.isLoading = false;
        payload && toast.error(payload);
      });
  },
});

export default clientSlice.reducer;
