import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { Input } from "../forms";
import { useFormik } from "formik";
import Button from "../Button";
import { editClientDetaails } from "../../features/clients/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import { getProviderSubscribersById } from "../../features/user/userSlice";
import * as Yup from "yup";
import PhoneInput from "../forms/PhoneInput";

const EditClientDetailsModal = ({
  setEditActive,
  editActive,
  client,
  id,
  clientType,
}) => {
  const dispatch = useDispatch();
  const [phoneDetails, setPhoneDetails] = useState({});
  const { isLoading } = useSelector((store) => store.client);
  const schema = Yup.object({
    email: Yup.string().email("invalid email"),
  });
  const {
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const { firstName, lastName, email, dob } = values;
      dispatch(
        editClientDetaails({
          firstName,
          lastName,
          email,
          phone: phoneDetails?.phoneNumber,
          dob,
          id: client?.id,
          callingCode: phoneDetails?.country?.dialCode,
        })
      ).then((res) => {
        if (res?.type === "user/editClientDetaails/fulfilled") {
          setEditActive(false);
          dispatch(getProviderSubscribersById({ id, clientType }));
        }
      });
    },
  });
  useEffect(() => {
    setFieldValue("firstName", client?.firstName);
    setFieldValue("lastName", client?.lastName);
    setFieldValue("email", client?.email);
    setFieldValue("phone", client?.phone);
    setFieldValue("dob", client?.dateOfBirth);
  }, []);
  return (
    <Modal
      isModalBig={true}
      title={"Edit Client Information"}
      handleClose={() => {
        setEditActive(false);
      }}
      show={editActive}
      containerClassName="addClients--modal"
    >
      <div className="inputgroup">
        <Input
          id="firstName"
          name="firstName"
          placeholder="First Name"
          label={"First Name"}
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          id="lastName"
          name="lastName"
          placeholder="Last Name"
          label={"Last Name"}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="inputgroup">
        <Input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          label={"Email"}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.email && errors.email ? (
              <small className="error">{errors.email}</small>
            ) : null
          }
        />
        <Input
          type="date"
          id="dob"
          name="dob"
          placeholder="DOB"
          label={"DOB"}
          value={values.dob}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <div className="phoneInput">
        <PhoneInput setPhoneDetails={setPhoneDetails} />
      </div>

      <div className="btn--container">
        <Button
          variant="secondary"
          type="submit"
          className="mt-6"
          block
          size={"md"}
          onClick={() => {
            setEditActive(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="mt-6"
          block
          size={"md"}
          onClick={() => {
            handleSubmit();
          }}
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </Modal>
  );
};

export default EditClientDetailsModal;
