import React from "react";
import Icon from "./Icon";
import Button from "./Button";

const SideModal = ({
  openSideModal,
  setOpenSideModal,
  headerText,
  children,
  handleClick,
  btnText,
  isCart,
  btnDisbled,
  isLoading,
  isBtn = true,
  handleSecondBtnClick,
  secondBtnLoading,
  isSecondBtn = false,
}) => {
  return (
    <div className={`cart__container ${openSideModal ? "backdrop" : ""}`}>
      <div className={`sidenav ${openSideModal ? "collapsed" : ""}`}>
        <div className="cart__container--header">
          {openSideModal && (
            <div className="close__btn">
              <Icon
                id="close"
                width="32"
                height="32"
                onClick={() => setOpenSideModal(false)}
              />
            </div>
          )}

          <div className="__details">
            {isCart && (
              <Icon
                id="cart-blue"
                width="23"
                height="21"
                onClick={() => setOpenSideModal(false)}
              />
            )}

            <p>{headerText}</p>
          </div>
        </div>
        <div className="cart-items">{children}</div>
        {isBtn ? (
          <div className="cart__container--footer">
            {isSecondBtn && (
              <div className="btn">
                <Button
                  type="button"
                  variant="secondary"
                  className="btn--block"
                  onClick={handleSecondBtnClick}
                  disabled={btnDisbled || secondBtnLoading}
                >
                  {secondBtnLoading ? "Loading..." : `${isSecondBtn}`}
                </Button>
              </div>
            )}
            <div className="btn">
              <Button
                type="button"
                variant="primary"
                className="btn--block"
                onClick={handleClick}
                disabled={btnDisbled || isLoading}
              >
                {isLoading ? "Loading..." : `${btnText}`}
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SideModal;
