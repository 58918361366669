import React from "react";
import Icon from "./Icon";
import { fmtCurrency } from "../utils/helper";
import ActiveBenefits from "./ActiveBenefits";
import { hasPermission } from "../utils/authHelpers";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
  LabelList,
} from "recharts";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
const DashboardCardSection = ({
  userRole,
  clients,
  providerCredit,
  clientVisit,
  revenueData,
  yearlyRevenue,
  monthlyRevenue,
  weeklyRevenue,
  selectedFilter,
  setSelectedFilter,
  openFilterList,
  setOpenFilterList,
  filterOptions,
  skeletonLoading,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={` w-full grid grid-cols-1 gap-[16px] ${
        hasPermission(userRole, "!OPERATOR")
          ? " lg:grid-cols-2 md:gap-x-[21px] gap-y-[31px]"
          : " lg:grid-cols-[1fr,1.3fr,1fr] md:gap-[20px]"
      }`}
    >
      {/*Clients Card*/}
      <div className="w-full h-full flex items-center flex-col gap-[16px] md:gap-0">
        <div className="w-full h-1/2 flex flex-row justify-start gap-[16px] items-center border border-[#D3D3D399] rounded-[12px] md:rounded-b-none px-5 py-4">
          <span className="p-[18px] rounded-[4px] bg-[#F5FBFF]">
            <Icon id="twoPeople" width="24" height="24" />
          </span>
          <div className="flex flex-col gap-[12px]">
            <p className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] uppercase">
              Active Clients
            </p>
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
                {clients?.data?.statistics?.totalActiveClients}
              </span>
              <span className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] capitalize">
                Last 30 days
              </span>
            </div>
          </div>
        </div>
        <div className="w-full h-1/2 flex flex-row justify-start gap-[16px] items-center border border-[#D3D3D399] rounded-[12px] md:rounded-t-none px-5 py-4">
          <span className="p-[18px] rounded-[4px] bg-[#F5FBFF]">
            <Icon id="person+" width="24" height="24" />
          </span>
          <div className="flex flex-col gap-[12px]">
            <p className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] uppercase">
              New Clients
            </p>
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
                {clients?.data?.statistics?.newClients}
              </span>
              <span className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] capitalize">
                Last 30 days
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*Balance Card*/}
      {hasPermission(userRole, "BRANCH_MANAGER") && (
        <div className="w-full h-full flex flex-col gap-[20px] justify-start items-start border border-[#D3D3D399] rounded-[12px] px-5 py-4">
          <div
            className={`flex items-center ${
              hasPermission(userRole, "REGIONAL_MANAGER")
                ? "justify-start gap-[30px]"
                : "justify-between"
            }`}
          >
            <div className="hover:rotate-[-6deg] transition-all duration-300">
              <div className="hidden sm:block">
                <Icon
                  id={
                    hasPermission(userRole, "REGIONAL_MANAGER")
                      ? "bigWalletCircle"
                      : "mdWalletCircle"
                  }
                  width="154"
                  height="152"
                />
              </div>
              <div className="block sm:hidden">
                <Icon id="smWalletCircle" width="64" height="64" />
              </div>
            </div>

            <div className="flex flex-col h-full justify-between items-start">
              <div className="flex flex-col items-start justify-start gap-[16px]">
                <span className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] uppercase">
                  BALANCE
                </span>
                <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
                  {fmtCurrency(providerCredit?.balance)}
                </span>
              </div>
              <div
                onClick={() => navigate("/transactions")}
                className="sm:flex hidden flex-row items-center justify-start gap-[6px] hover:cursor-pointer hover:translate-x-1 transition-all duration-300 "
              >
                <span className="text-[14px] tracking-[0.02em] font-[600] text-[#005397] uppercase underline">
                  SEE TRANSACTION DETAILS
                </span>
                <Icon id="rightArrow-2" width="14" height="14" />
              </div>
            </div>
          </div>
          <div
            onClick={() => navigate("/transactions")}
            className="flex sm:hidden flex-row items-center justify-start gap-[6px] hover:cursor-pointer hover:translate-x-1 transition-all duration-300 "
          >
            <span className="text-[14px] tracking-[0.02em] font-[600] text-[#005397] uppercase underline">
              SEE TRANSACTION DETAILS
            </span>
            <Icon id="rightArrow-2" width="14" height="14" />
          </div>
        </div>
      )}

      {/*Members Card*/}
      <div className="w-full h-full flex flex-col gap-[20px] justify-start items-start border border-[#D3D3D399] rounded-[12px] px-5 py-4">
        <div
          className={`flex items-center  
          ${
            hasPermission(userRole, "<REGIONAL_MANAGER")
              ? hasPermission(userRole, "<BRANCH_MANAGER")
                ? "justify-between"
                : "justify-start gap-[20px]"
              : "justify-start gap-[20px]"
          }`}
        >
          <div className="flex flex-col items-center justify-center sm:justify-between h-full">
            <span className="hover:rotate-[-2deg] hover:translate-y-[-2px] transition-all duration-300">
              <div className="hidden sm:block">
                <Icon id="bigPersonCircle" width="118" height="122" />
              </div>
              <div className="block sm:hidden">
                <Icon id="smPersonCircle" width="64" height="64" />
              </div>
            </span>
            <div
              onClick={() => navigate("/members")}
              className="sm:flex hidden flex-row flex-nowrap items-center justify-start gap-[6px] hover:cursor-pointer hover:translate-x-1 transition-all duration-300 "
            >
              <span className="text-[14px] tracking-[0.02em] font-[600] text-[#005397] uppercase underline text-nowrap">
                SEE MEMBERS
              </span>
              <Icon id="rightArrow-2" width="14" height="14" />
            </div>
          </div>

          <div className="flex flex-col h-full justify-between items-start gap-[16px]">
            <span className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] uppercase text-nowrap">
              MEMBERSHIP EXPIRING
            </span>

            <div className="flex flex-row items-start justify-start gap-[8px]">
              <span className="text-[14px] font-[500] h-[28px] text-start min-w-[84px] text-[#E0063C] bg-[#FFF3F3] px-2 py-1 rounded-[4px]">
                Today
              </span>
              <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
                {clients?.data?.statistics?.expiringTodayCount}
              </span>
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <span className="text-[14px] font-[500] h-[28px] text-start min-w-[84px] text-[#E68F00] bg-[#FFFAF3] px-2 py-1 rounded-[4px]">
                This Week
              </span>
              <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
                {clients?.data?.statistics?.expiringThisWeekCount}
              </span>
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <span className="text-[14px] font-[500] h-[28px] text-start min-w-[84px] text-[#5F6062] bg-[#F5F5F5] px-2 py-1 rounded-[4px]">
                Expired
              </span>
              <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
                {clients?.data?.statistics?.expiredCount}
              </span>
            </div>
          </div>
        </div>
        <div
          onClick={() => navigate("/members")}
          className="sm:hidden flex flex-row flex-nowrap items-center justify-start gap-[6px] hover:cursor-pointer hover:translate-x-1 transition-all duration-300 "
        >
          <span className="text-[14px] tracking-[0.02em] font-[600] text-[#005397] uppercase underline text-nowrap">
            SEE MEMBERS
          </span>
          <Icon id="rightArrow-2" width="14" height="14" />
        </div>
      </div>

      {/*Visits Card*/}
      <div
        className={`flex border border-[#D3D3D399] rounded-[12px] px-5 py-4 
          ${
            hasPermission(userRole, "=OPERATOR")
              ? "items-center justify-start gap-5 lg:items-start lg:justify-between lg:flex-col-reverse xl:items-center xl:justify-start xl:gap-5 xl:flex-row"
              : "items-center justify-start gap-5"
          }`}
      >
        <div
          className={`flex flex-col items-center justify-center box-border border-[8px] border-[#005397] rounded-full
            ${
              hasPermission(userRole, "<BRANCH_MANAGER")
                  ? "h-[119px] w-[119px]"
                : "h-[156px] w-[156px]"
            }
            `}
        >
          <span className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] uppercase">
            VISITS
          </span>
          <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
            <CountUp
              start={0}
              end={clientVisit?.totalFlancePassVisits || 0}
              duration={2}
              decimals={0}
            />
          </span>
        </div>

        <div className="flex flex-col justify-center items-start">
          <span className="text-[20px] leading-[30px] tracking-[0.005em] font-[600] text-[#0C0D0F]">
            Visits Logged
          </span>
          <span className="text-[14px] leading-[20px] font-[500] text-[#5C6368]">
            Last 30 days
          </span>
        </div>
      </div>

      {/*Revenue Bar Chart Card some tailwind in here*/}
      {hasPermission(userRole, "BRANCH_MANAGER") && (
        <div className="overview__bar lg:col-span-2">
          <div className="overview__bar--left w-full ">
            <div className="header">
              <div className="header--left">
                <h3>Revenue Overview</h3>
                <p>{fmtCurrency(revenueData?.totalRevenue)}</p>
              </div>
              <div className="header--right">
                <div className="search__header">
                  <div className="search__header--container">
                    <div
                      className="search__header--filterContainer"
                      onMouseLeave={() => setOpenFilterList(false)}
                    >
                      <div
                        className="search__header--filter"
                        onMouseOver={() => setOpenFilterList(true)}
                      >
                        <p>{selectedFilter?.name || "Filter by"}</p>
                        <Icon id="down-caret" width="10" height="4" />
                      </div>
                      {openFilterList && (
                        <div className="filterList">
                          <ul>
                            {filterOptions.map((item, index) => (
                              <li
                                onClick={() => {
                                  setSelectedFilter(item);
                                }}
                                key={index}
                              >
                                {item?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="barchart">
              <ResponsiveContainer width="100%" height="100%">
                {revenueData?.totalRevenue < 1 ? (
                  <ActiveBenefits
                    icon={skeletonLoading}
                    text={`Your revenue data will appear here. Start generating transactions to see your earnings!`}
                    centerHeight={false}
                  />
                ) : (
                  <BarChart
                    data={
                      selectedFilter?.value === "THIS_YEAR"
                        ? yearlyRevenue
                        : selectedFilter?.value === "THIS_MONTH"
                        ? monthlyRevenue
                        : weeklyRevenue
                    }
                    barSize={29}
                    margin={{ top: 15, right: 30, left: 20, bottom: 20 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                      dataKey="month"
                      width={20}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis axisLine={false} tickLine={false} />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: "transparent" }}
                    />

                    <Bar
                      dataKey="revenue"
                      background={false}
                      fill="#005397"
                      radius={[4, 4, 4, 4]}
                      activeBar={<Rectangle fill="#029AFF" stroke="#029AFF" />}
                    >
                      <LabelList dataKey="revenue" position="top" />
                    </Bar>
                  </BarChart>
                )}
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}

      {/*BRANCH MANAGER ONLY VISIT CARD
        Can not be implemented
        hasPermission(userRole, "=BRANCH_MANAGER") && (
        <div className={`flex border border-[#D3D3D399] rounded-[12px] px-5 py-4 
          ${hasPermission(userRole, "<REGIONAL_MANAGER") 
            ? hasPermission(userRole, "<BRANCH_MANAGER") 
              ?"items-center justify-start gap-5 flex-row-reverse min-w-[190px]" 
              :"items-start justify-between flex-col-reverse"
            : "items-center justify-start gap-5"
          }`}>
          <div className={`flex flex-col items-center justify-center box-border border-[8px] border-[#005397] rounded-full
            ${hasPermission(userRole, "<REGIONAL_MANAGER") 
              ? hasPermission(userRole, "<BRANCH_MANAGER") 
                ?"h-[119px] w-[119px]" 
                :" w-full aspect-square"
              : "h-[156px] w-[156px]"
            }
            `}
          >
            <span className="text-[14px] leading-[20px] tracking-[0.02em] font-[500] text-[#5C6368] uppercase">
              VISITS
            </span>
            <span className="text-[24px] leading-[28px] tracking-[0.02em] font-[600] text-[#000B14]">
              {clientVisit?.totalFlancePassVisits}
            </span>
          </div>

          <div className="flex flex-col justify-center items-start">
            <span className="text-[20px] leading-[30px] tracking-[0.005em] font-[600] text-[#0C0D0F]">
              Visits Logged
            </span>
            <span className="text-[14px] leading-[20px] font-[500] text-[#5C6368]">
              Last 30 days
            </span>
          </div>
        </div>
        
        */}
    </div>
  );
};

export default DashboardCardSection;

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "12px",
          boxShadow: "0px 1px 10px 0px #029AFF80;",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <p
            style={{
              color: "#5C6368",
              fontWeight: "400",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <span
              style={{
                display: "block",
                width: "10px",
                height: "10px",
                background: "#005397",
                borderRadius: "50%",
              }}
            ></span>
            Revenue:
          </p>
          <p style={{ color: "#000B14", fontWeight: 600 }}>
            {fmtCurrency(payload[0].value)}
          </p>
        </div>
      </div>
    );
  }
  return null;
};
