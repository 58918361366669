import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { Input } from "../forms";
import Icon from "../Icon";
import BigModal from "./BigModal";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProviderBenefit,
  updateProviderBenefits,
} from "../../features/user/userSlice";
import { fmtCurrency } from "../../utils/helper";
import { hasPermission } from "../../utils/authHelpers";
const allDurations = [
  { duration: "DAILY", price: "" },
  { duration: "WEEKLY", price: "" },
  { duration: "BI_WEEKLY", price: "" },
  { duration: "MONTHLY", price: "" },
  { duration: "QUARTERLY", price: "" },
  { duration: "ANNUALLY", price: "" },
  { duration: "BIANNUALLY", price: "" },
];

const PlanPriceModal = ({
  showPriceModal,
  setShowPriceModal,
  plan,
  setShowDeleteModal,
}) => {
  const { submitClientsManuallyLoading, providerCredit } = useSelector(
    (store) => store.user
  );
  const [inputEnabled, setInputEnabled] = useState(false);
  const [priceList, setPriceList] = useState([]);
  // const [membershipFeePrice, setMembershipFeePrice] = useState("");
  const [updateName, setUpdateName] = useState("");
  const { user } = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;

  const dispatch = useDispatch();
  const submitChanges = () => {
    dispatch(
      updateProviderBenefits({
        id: plan?.id,
        price: priceList.filter((option) => option.price !== ""),
        benefitName: updateName ? updateName : plan?.benefitName,
      })
    ).then((res) => {
      if (res?.type === "user/updateProviderBenefits/fulfilled") {
        dispatch(fetchProviderBenefit());
        setInputEnabled(false);
        setShowPriceModal(false);
      }
    });
  };

  useEffect(() => {
    setPriceList(
      allDurations.map((option) => {
        const match = plan?.priceOptions?.find(
          (priceOption) => priceOption.duration === option.duration
        );
        return {
          ...option,
          price: match ? match.price : "",
        };
      })
    );
  }, [plan]);
  const handlePriceChange = (optionIndex, newPrice) => {
    setPriceList((prevPriceList) => {
      const updatedPriceList = [...prevPriceList];
      updatedPriceList[optionIndex] = {
        ...updatedPriceList[optionIndex],
        price: Number(newPrice),
      };
      return updatedPriceList;
    });
  };

  return (
    <BigModal
      isModalOpen={showPriceModal}
      isVisible={showPriceModal}
      handleClose={() => setShowPriceModal(false)}
      headerTitle={"Plan Details"}
    >
      <div className="planPricing">
        <div className="planPicing--header__info">
          <p>
            <Icon id="listItem" width="16" height="16" />{" "}
            {inputEnabled ? "Enter Plan name" : "Plan name"}
          </p>
          <div className="add--input mt-4 ">
            {inputEnabled ? (
              <Input
                id={`benefitName`}
                name={`benefitName`}
                placeHolder="(e.g., Monthly, Couple, Family, Weekend, Day Pass, Weekly)"
                isFloatingLabel={false}
                value={updateName}
                onChange={(e) => setUpdateName(e.target.value)}
              />
            ) : (
              <p>{plan?.benefitName}</p>
            )}
          </div>
          <div className="price__duration">
            <p>
              <Icon id="calender" width="16" height="16" />{" "}
              {inputEnabled
                ? "Select Plan Price & Duration"
                : "Plan Price and Duration"}
            </p>

            <div className="price__duration--lists mt-4 ">
              {priceList.map((option, optionIndex) => (
                <>
                  {inputEnabled ? (
                    <div className="list" key={option.duration}>
                      <input
                        id={option.duration}
                        type="number"
                        value={option.price}
                        onChange={(e) =>
                          handlePriceChange(optionIndex, e.target.value)
                        }
                        placeHolder="₦ 0000 000"
                        disabled={!inputEnabled}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          ["e", "E", "-"].includes(e.key) && e.preventDefault()
                        }
                      />
                      <span className={`${option.price ? "active" : ""}`}>
                        {option.duration === "BI_WEEKLY"
                          ? "Bi-weekly"
                          : option.duration.toLowerCase()}
                      </span>
                    </div>
                  ) : (
                    <div className="list priceplan">
                      <p className="price">
                        {option.duration === "BI_WEEKLY"
                          ? "Bi-weekly"
                          : option.duration.toLowerCase()}
                      </p>

                      <p>{option.price ? fmtCurrency(option.price) : "--"}</p>
                    </div>
                  )}
                </>
              ))}
              {/* {inputEnabled ? (
                <div className="list">
                  <input
                    id={"membership"}
                    type="number"
                    value={membershipFeePrice}
                    onChange={(e) => setMembershipFeePrice(e.target.value)}
                    placeHolder="₦ 0000 000"
                    disabled={!inputEnabled}
                    onKeyDown={(e) =>
                      ["e", "E", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  <span className={`${"active"}`}>MemberShip fee</span>
                </div>
              ) : (
                <div className="list priceplan">
                  <p className="price">Membership fee</p>

                  <p>{fmtCurrency(membershipFeePrice)}</p>
                </div>
              )} */}
            </div>
          </div>
          { hasPermission(userRole, "BRANCH_MANAGER") && (
          <div className="plan--deets">
            <label>
              <input
                type="checkbox"
                checked={plan.includesPersonalTrainer}
                disabled
              />
              Includes personal trainer
            </label>
            <label>
              <input type="checkbox" checked={plan.hasGuestPass} disabled />
              Allow guest pass
            </label>
          </div>
          )}

          {/* <div className="plan--deets">
            <div className="showOnFlance">
              <div className="toggle--container">
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name={`membershipFee`}
                    id={`membershipFee`}
                    checked={plan?.applyMembershipFee}
                    disabled
                    //   onChange={() => handleCheckboxChange(index, "membershipFee")}
                  />
                  <label className="label" htmlFor={`membershipFee`}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
                Allow Membership Fee
              </div>
            </div>
          </div> */}
          { hasPermission(userRole, "BRANCH_MANAGER") && (
          <div className="plan--deets">
            <div className="showOnFlance">
              <div className="toggle--container">
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name={`publishOnFlance`}
                    id={`publishOnFlance`}
                    checked={plan.publishOnFlance}
                    disabled
                    //   onChange={() =>
                    //     handleCheckboxChange(index, "publishOnFlance")
                    //   }
                  />
                  <label className="label" htmlFor={`publishOnFlance`}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
                Show plan on Flance
              </div>
            </div>
            {providerCredit?.commission && (
              <div className="commission">
                <Icon id="light-bulb" width="20" height="16" />
                <p>
                  A {providerCredit?.commission}% is retained from the price of
                  each plan sold on Flance.
                </p>
              </div>
            )}
          </div>
          )}
        </div>
        <div className="btn--container">
          {inputEnabled && hasPermission(userRole, "ADMIN") && (
            <Button
              variant="secondary"
              type="submit"
              className="mt-6"
              block
              size={"md"}
              onClick={() => {
                setShowPriceModal(false);
                setShowDeleteModal(true);
              }}
            >
              <span className="penIcon">
                <Icon id={"trash"} height={"21"} width={"21"} />
              </span>
              Delete Plan
            </Button>
          )}
          { hasPermission(userRole, "BRANCH_MANAGER") && (
          <Button
            variant="primary"
            type="submit"
            className="mt-6"
            block
            size={"md"}
            onClick={() => {
              if (inputEnabled) {
                submitChanges();
              } else {
                setInputEnabled(true);
              }
            }}
          >
            {inputEnabled ? (
              <>{submitClientsManuallyLoading ? "Saving..." : "SAVE CHANGES"}</>
            ) : (
              <>
                {" "}
                <span className="penIcon">
                  <Icon id={"pencil"} height={"21"} width={"21"} />
                </span>
                {""}Edit
              </>
            )}
          </Button>
          )}
        </div>
      </div>
    </BigModal>
  );
};

export default PlanPriceModal;
