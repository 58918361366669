import React, { useEffect, useState } from "react";
import Icon from "./Icon";
import { useDispatch, useSelector } from "react-redux";
import { getProviderBranch, logoutUser } from "../features/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import nProgress from "nprogress";
import Logo from "../assets/svgs/logo.svg";
import { hasPermission } from "../utils/authHelpers";
const DashboardHeader = ({ setSlidingSidebar, slidingSidebar }) => {
  const { navTitle, user } = useSelector((store) => store.user);
  const data = user?.data;
  const { providerBranch,} = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;
  const [selectedBranch, setSelectedBranch] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);

  const [openDropdownBrach, setOpenDropdownBranch] = useState(false);

  const [openDropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    nProgress.start();
    setTimeout(() => {
      nProgress.done();
      dispatch(logoutUser());
      navigate("/auth/login");
    }, 500);
  };

  useEffect(() => {
    dispatch(getProviderBranch());
  }, []);

  useEffect(() => {
    // const storedData = localStorage.getItem("branch");
    const getFromLocalStorage = (key) => {
      try {
        const value = localStorage.getItem(key);
        if (value === null || value === undefined || value === "undefined") {
          return null; // Return null if the key doesn't exist
        }
        setSelectedBranch(JSON.parse(value)); // Parse the JSON value
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
        return null; // Return null if parsing fails
      }
    };
    getFromLocalStorage("branch");
  }, [selectedItem]);


  const location = useLocation();

  return (
    <>
      <nav className={`topnav`}>
        <div className="topnav--wrapper">
          <div className="brand">
          {/*
            <Icon
              id={"hamburger"}
              width={"24"}
              height={"21"}
              onClick={() => setSlidingSidebar(!slidingSidebar)}
            />
            */}
            <img src={Logo} alt="fla" className="logo"/>
            {location?.pathname !== "/onboarding" &&
              providerBranch?.branches?.length > 1 && hasPermission(userRole, "REGIONAL_MANAGER") && (
                <div
                  className="header__branch"
                  onMouseOver={() => setOpenDropdownBranch(true)}
                  onMouseLeave={() => setOpenDropdownBranch(false)}
                >
                  <div className="branch--pick">
                    <Icon id={"location"} width={"14"} height={"21"} />
                  </div>
                  {openDropdownBrach && (
                    <div className="branch__dropdown">
                      <ul>
                        {providerBranch?.branches?.map((branch, index) => (
                          <li
                            onClick={() => {
                              setSelectedItem(branch);
                              localStorage.setItem(
                                "branch",
                                JSON.stringify(branch)
                              );
                            }}
                          >
                            <h4>{branch?.name}</h4>
                            <p>{branch?.city}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            <h2>{navTitle ? navTitle : ""}</h2>
          </div>

          <div
            className="topnav-right d-iflx al-i-c"
            onMouseOver={() => setDropdown(true)}
            onMouseLeave={() => setDropdown(false)}
          >
            <div className="avatar-circle">
              <div className="avatar-circle__placeholder iflx-center-center">
                <p className="avatar-circle__initials iflx-center-center">
                  {data?.providerName?.slice(0, 2)}
                </p>
              </div>
            </div>
            <div className="d-iflx al-i-c">
              <p className="username mr-8">{data?.providerName}</p>
              <Icon width="8px" height="5px" id="drop-icon" />
              {openDropdown && (
                <ul className="topnav__dropdown">
                  <li
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    <span className="">Settings</span>
                  </li>
                  <li
                    onClick={() => {
                      logout();
                    }}
                  >
                    <span className="logoutBtn">Logout</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default DashboardHeader;
