import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeNavtitle } from "../../features/user/userSlice";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/authHelpers";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(changeNavtitle("Settings"));
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const {user } = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;
  return (
    <div className="settings">
      <div className="settings__header">
        <ul>
          <li
            onClick={() => navigate("/settings")}
            className={`${!splitLocation[2] ? "active" : ""}`}
          >
            My Account
          </li>
          { hasPermission(userRole,"BRANCH_MANAGER") && (
          <li
            onClick={() => navigate("/settings/rolemanagement")}
            className={`${splitLocation[2] === "rolemanagement" ? "active" : ""}`}
          >
            Team Members
            </li>
          )}
          <li
            onClick={() => navigate("/settings/branch")}
            className={`${splitLocation[2] === "branch" ? "active" : ""}`}
          >
            Branch
          </li>
          <li
            onClick={() => navigate("/settings/keyperson")}
            className={`${splitLocation[2] === "keyperson" ? "active" : ""}`}
          >
            Key Person
          </li>
          <li
            onClick={() => navigate("/settings/security")}
            className={`${splitLocation[2] === "security" ? "active" : ""}`}
          >
            Security
          </li>
        </ul>
        <div className="settings__body">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Settings;
