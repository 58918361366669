import React, { useState } from "react";
import Icon from "../Icon";
import Button from "../Button";
import { Input } from "../forms";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderCreditTransactions,
  submitPassCode,
} from "../../features/user/userSlice";
import { getClientVisits } from "../../features/clients/clientSlice";

const PassVerificationStatusModal = ({
  openFlancePassStatusModal,
  isVisible,
  handleClose,
  status = "invalid", // can be 'ok', 'expired', or 'invalid'
  clientName,
  storedData,
  setVerificationStatus,
  setVerifiedClientName,
  verificationErrorMessage,
  setVerificationErrorMessage,
}) => {
  const { passDetails, submitClientsManuallyLoading } = useSelector(
    (store) => store.user
  );
  const statusConfig = {
    ok: {
      icon: "check-circle",
      title: "Yay! Code validated successfully.",
      text: "Your balance has been updated.",
      buttonText: "Okay",
    },
    expired: {
      icon: "cancel-circle",
      title: "Oops, this code has expired.",
      text: "This code has expired. Please request a new one.",
      buttonText: "Okay",
    },
    other: {
      icon: "cancel-circle",
      title: verificationErrorMessage
        ? verificationErrorMessage
        : "Oops, Something went wrong.",
      text: "Please try again later",
      buttonText: "Okay",
    },
    invalid: {
      icon: "retry-circle",
      title: "Oops, this code is invalid.",
      text: "Enter a valid code.",
      buttonText: submitClientsManuallyLoading ? "Retrying..." : "Retry",
    },
  };

  const currentStatus = statusConfig[status];

  const [passInfo, setPassInfo] = useState("");
  const dispatch = useDispatch();
  const submitCode = () => {
    dispatch(
      submitPassCode({
        accessCode: passInfo,
        visitTimestamp: new Date(),
        providerBranchId: storedData?.id,
      })
    ).then((res) => {
      if (res?.type === "user/submitPassCode/fulfilled") {
        setVerificationStatus("ok");
        // Format the name as "Firstname .L"
        const firstName = res.payload?.data?.firstName || "";
        const lastInitial = res.payload?.data?.lastName?.charAt(0) || "";
        const formattedName = `${firstName} .${lastInitial}`;
        setVerifiedClientName(formattedName);
        dispatch(getProviderCreditTransactions());
        dispatch(getClientVisits({ branch: storedData?.id }));
      } else {
        // Handle different error cases based on the response
        if (res?.payload === "Oops, This Passcode has Expired") {
          setVerificationStatus("expired");
        } else if (res?.payload === "Oops, This Passcode is Invalid") {
          setVerificationStatus("invalid");
        } else {
          setVerificationStatus("other");
          setVerificationErrorMessage(res?.payload);
        }
      }
    });
    setPassInfo("");
  };

  return (
    <div>
      {isVisible && (
        <div
          className={`modal-overlay-bg  ${
            openFlancePassStatusModal ? "active" : ""
          }`}
        >
          <div className="modal-content-bg--variant">
            <div className="modal-content-bg--variant__header">
              <p>VERIFICATION</p>
              <div className="closebtn">
                <Icon
                  width="48"
                  height="48"
                  id="closeBtn3"
                  onClick={handleClose}
                />
              </div>
            </div>

            <div className="modal-content-bg--variant__body">
              <div className="notification__container">
                <Icon width="64" height="64" id={currentStatus.icon} />
                <div className="text_container">
                  <div className="notification__title">
                    <p>{currentStatus.title}</p>
                  </div>
                  <div className="notification__text">
                    <p>{currentStatus.text}</p>
                  </div>
                </div>
                {status === "ok" && clientName && (
                  <div className="new_client_container">
                    <div className="new_client_name">
                      <p>{clientName}</p>
                    </div>
                    <div className="sub_text">
                      <p>You gained a new client for the day.</p>
                    </div>
                  </div>
                )}
                {status === "invalid" && (
                  <Input
                    className={""}
                    placeHolder={"Enter Code"}
                    isFloatingLabel={false}
                    value={passInfo}
                    onChange={(e) => setPassInfo(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="modal-content-bg--variant__footer">
              <Button
                onClick={
                  status === "invalid"
                    ? submitCode
                    : () => {
                        handleClose();
                      }
                }
                variant={"primary"}
                block
                className=""
              >
                {currentStatus.buttonText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PassVerificationStatusModal;
