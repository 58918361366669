import React, { useState } from "react";
import Icon from "./Icon";

const PaginationWithApi = ({
  handlePrev,
  handleNext,
  data,
  setCurrentPage,
}) => {
  const [showNumbers, setShowNumbers] = useState(false);
  const allNumbers = Array.from({ length: data?.totalPages }, (_, i) => i);
  return (
    <div className="table--pagination">
      <div className="pagecontrols">
        <div
          className="container showmore--container"
          onMouseOver={() => setShowNumbers(true)}
          onMouseLeave={() => setShowNumbers(false)}
        >
          <p>Show more</p>
          <Icon width="9" height="14" id="left-caret" />
          {showNumbers && (
            <div className="showOptions">
              <div className="showOption--numbers">
                {allNumbers.map((num) => (
                  <div
                    className="numbers"
                    key={num}
                    onClick={() => setCurrentPage(num)}
                  >
                    {num + 1}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="container">
          <p className="pageNumber">
            {`page ${data?.number + 1 ? data?.number + 1 : 1} of ${
              data?.totalPages ? data?.totalPages : "1"
            }`}
          </p>
        </div>
      </div>

      <div className="pages">
        <div className="next container">
          <Icon width="9" height="14" id="left-caret" />
          <span onClick={handlePrev}>Prev</span>
        </div>

        <div className="next container">
          <span onClick={handleNext}>NEXT</span>
          <Icon width="9" height="14" id="right-caret" />
        </div>
      </div>
    </div>
  );
};

export default PaginationWithApi;
