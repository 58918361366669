import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/forms/Input";
import Button from "../../components/Button";
import nProgress from "nprogress";
import { useDispatch, useSelector } from "react-redux";
import {
  resetProviderPassword,
  returnChangePassword,
} from "../../features/user/userSlice";

const ResetPassword = () => {
  const userDetail = JSON.parse(localStorage.getItem("loginData"));
  const navigate = useNavigate();
  const { isLoading, passwordChanged } = useSelector((store) => store.user);
  const [isPasswordField, setIsPasswordField] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [special, setSpecial] = useState(false);
  const [length, setLength] = useState(false);

  const dispatch = useDispatch();
  const schema = Yup.object({
    password: Yup.string()
      .required("Required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/^(?=.*[!@#$%^&*_-])/, "Must Contain One Special Character"),
  });
  const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: schema,
      onSubmit: async (values) => {
        dispatch(
          resetProviderPassword({
            password: values?.password,
            username: userDetail?.name,
            id: userDetail?.id,
          })
        );
      },
    });
  useEffect(() => {
    if (values.password) {
      setUpperCase(/[A-Z]/g.test(values.password));
      setLowerCase(/[a-z]/g.test(values.password));
      setSpecial(/[!@#$%^&*_-]/g.test(values.password));
      setLength(values.password.length >= 8);
    }
    if (values.password === "") {
      setUpperCase(false);
      setLowerCase(false);
      setSpecial(false);
      setLength(false);
    }
  }, [values.password]);

  useEffect(() => {
    dispatch(returnChangePassword());
  }, []);

  useEffect(() => {
    if (isLoading) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [isLoading]);

  useEffect(() => {
    if (passwordChanged) {
      navigate("/auth/login");
    }
  }, [passwordChanged]);
  return (
    <>
      <div className="toaster__style">
        {/* <Toaster position="top-right" reverseOrder={false} /> */}
      </div>
      <div className="auth__form--content">
        <div className="auth__form--header">
          <div className="header__container">
            <div className="header__container--content">
              <h3>Reset Password</h3>
              <p>
                Use a password different from your previously used passwords and
                Make sure it is something you can easily remember.
              </p>
            </div>
          </div>

          {/* <small className="error">{error}</small> */}
        </div>
        <form className="__form" onSubmit={handleSubmit}>
          <Input
            type="password"
            id="password"
            placeholder="New Password"
            label="New Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={() => setIsPasswordField(true)}
            error={
              touched.password && errors.password ? (
                <small className="error">{errors.password}</small>
              ) : null
            }
          />
          {isPasswordField && (
            <div className="password-rules">
              <div className="lineIndicator">
                <div className={`div ${length ? "passed" : ""}`}></div>
                <div className={`div ${upperCase ? "passed" : ""}`}></div>
                <div className={`div ${lowerCase ? "passed" : ""}`}></div>
                <div className={`div ${special ? "passed" : ""}`}></div>
              </div>
              <div className="actualRules">
                <div className={`rule ${length ? "active" : ""}`}>
                  <div className="circle"></div>
                  <p>8 or more characters</p>
                </div>
                <div className={`rule ${upperCase ? "active" : ""}`}>
                  <div className="circle"></div>
                  <p>An uppercase letter</p>
                </div>
                <div className={`rule  ${lowerCase ? "active" : ""}`}>
                  <div className="circle"></div>
                  <p>A lowercase letter</p>
                </div>
                <div className={`rule  ${special ? "active" : ""}`}>
                  <div className="circle "></div>
                  <p>A symbol (=!@#&$%)</p>
                </div>
              </div>
            </div>
          )}

          <Button
            variant="primary"
            type="submit"
            className="mt-10"
            block
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Set password"}
          </Button>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
