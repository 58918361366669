import React, { useEffect } from 'react';
import Icon from './Icon';

const HeadwayWidget = () => {
	useEffect(() => {
		// Check if Headway and config are available and initialize it
		if (window.Headway && window.HW_config) {
			window.Headway.init(window.HW_config);
		}
	}, []);

	return (
		<div className="headway-changelog-widget">
			<div className="headway-changelog-widget--left">
				<Icon id="lightbulb-white" width="15" height="13" />
				<span>{"See What's New"}</span>
			</div>
		</div>
	);
};

export default HeadwayWidget;
