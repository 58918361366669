import React, { useState } from "react";
import Table from "./Table";
import { dateFormat } from "../../utils/helper";
import Icon from "../Icon";

const MemberTable = ({
  data,
  setRenewalNotificationModal,
  handleCheck,
  handleCheckAll,
  setClientToSendMail,
  setIsEmail,
}) => {
  const columns = [
    "check",
    "Name",
    "Membership Expiry Date",
    "Renewal Status",
    "Notify",
  ];

  const [openInfo, setOpenInfo] = useState(null);
  const [openPhoneInfo, setOpenPhoneInfo] = useState(null);
  const handleMailClickHover = (id, actionType) => {
    if (actionType === "hover") {
      setOpenInfo(id);
    } else {
      if (!id?.email) {
        return;
      } else {
        setIsEmail(true);
        setRenewalNotificationModal(true);
        setClientToSendMail(id);
      }
    }
  };

  const handlePhoneClickHover = (id, actionType) => {
    if (actionType === "hover") {
      setOpenPhoneInfo(id);
    } else {
      if (!id?.phone) {
        return;
      } else {
        setIsEmail(false);
        setRenewalNotificationModal(true);
        setClientToSendMail(id);
      }
    }
  };

  return (
    <div>
      <Table
        columns={columns}
        hasCheckBoxHeader={true}
        handleCheckAll={handleCheckAll}
      >
        {data?.map((row, index) => (
          <tr className="pointer grey-bg" key={index}>
            <td>
              <input
                type="checkbox"
                className="check"
                checked={row.isChecked}
                onChange={() => handleCheck(row?.clientId)}
              />
            </td>
            <td>{row?.firstName + " " + row?.lastName}</td>
            <td className="">
              {dateFormat(row.renewalDate, "MMM dd, yyyy hh:mm a")}
            </td>

            <td>
              <span
                className={`status ${
                  row?.renewalStatus === "Expired"
                    ? "status-inactive"
                    : row?.renewalStatus === "Today"
                    ? "status-warning"
                    : "pending"
                }`}
              >
                <span>
                  <Icon
                    id={
                      row?.renewalStatus === "Expired"
                        ? "expiredCircle"
                        : row?.renewalStatus === "Today"
                        ? "warningCircle"
                        : "pendingCircle"
                    }
                    width="12"
                    height="12"
                  />
                </span>
                {row?.renewalStatus}
              </span>
            </td>
            <td className="mailContact">
              <div className="mailContainer">
                <span
                  className={row?.email ? "mail" : " mail mail--disabled"}
                  onMouseOver={() =>
                    handleMailClickHover(row?.clientId, "hover")
                  }
                  onMouseLeave={() => setOpenInfo(null)}
                >
                  <Icon
                    id={row?.email ? "mail@" : "greyMail@"}
                    width="24"
                    height="24"
                    onClick={() => handleMailClickHover(row, "click")}
                  />
                  <div className="dropdown-mail">
                    {openInfo === row?.clientId && (
                      <div className="topnav__dropdown">
                        {row?.email ? (
                          <div className="infobox">
                            <Icon id="infoBlue" width="24" height="24" />
                            <p>Send renewal notification via email</p>
                          </div>
                        ) : (
                          <div className="infoboxError">
                            <div className="noContact">
                              <Icon id="infoBlue" width="24" height="24" />
                              <p>Email not available</p>
                            </div>
                            <div className="noContact--body">
                              <p>
                                Email not available for this client. Please
                                update the client's email on the client details
                                page.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </span>
              </div>
              <div className="mailContainer">
                <span
                  className={row?.phone ? "mail" : " mail mail--disabled"}
                  onMouseOver={() =>
                    handlePhoneClickHover(row?.clientId, "hover")
                  }
                  onMouseLeave={() => setOpenPhoneInfo(null)}
                >
                  <Icon
                    id={row?.phone ? "textIcon" : "greytextIcon"}
                    width="24"
                    height="24"
                    onClick={() => handlePhoneClickHover(row, "click")}
                  />
                  <div className="dropdown-mail">
                    {openPhoneInfo === row?.clientId && (
                      <div className="topnav__dropdown">
                        {row?.phone ? (
                          <div className="infobox">
                            <Icon id="infoBlue" width="24" height="24" />
                            <p>Send renewal notification via SMS</p>
                          </div>
                        ) : (
                          <div className="infoboxError">
                            <div className="noContact">
                              <Icon id="infoBlue" width="24" height="24" />
                              <p>Phone number not available</p>
                            </div>
                            <div className="noContact--body">
                              <p>
                                Phone number not available for this client.
                                Please update the client's phone number on the
                                client details page
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default MemberTable;
