export const navigations = [
  {
    id: 0,
    link: "/",
    icon: "dash",
    name: "Dashboard",
    navName: "",
    coloredIcon: "blue-dash",
    floatingIcon: "dashboard-white-sm",
    floatingColoredIcon: "dashboard-blue-sm",
  },
  {
    id: 1,
    link: "/clients",
    icon: "clients",
    name: "Clients",
    navName: "clients",
    coloredIcon: "blue-clients",
    floatingIcon: "client-white-sm",
    floatingColoredIcon: "client-blue-sm",
  },
  {
    id: 2,
    link: "/plans",
    icon: "list-plan",
    name: "Plans",
    navName: "plans",
    coloredIcon: "list-blues",
    floatingIcon: "plan-white-sm",
    floatingColoredIcon: "plan-blue-sm",
  },
];
