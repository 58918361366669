import React, { useEffect, useRef, useState } from "react";
// import Icon from 'components/Icon';
import Icon from "../Icon";

const Input = ({
  label,
  type = "text",
  name,
  onChange,
  value = "",
  error,
  placeholder,
  placeHolder,
  min,
  max,
  onClick,
  onFocus,
  onBlur,
  className,
  isDate = false,
  suffixIcon=isDate?"calender":"",
  isFloatingLabel = true,
  size,
  disabled = false,
  onKeyDown,
  isHelperText,
  helperText,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef(null);
  const labelRef = useRef(null);

  useEffect(() => {
    if (value !== "") {
      labelRef.current.classList.add("active");
    }
  }, [value]);

  const handleFocus = (e) => {
    labelRef.current.classList.add("active");

    if (onFocus) {
      onFocus(e);
    }
    if (isDate === true) {
      inputRef.current.type = "date";
    }
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      labelRef.current.classList.remove("active");
    } else {
      labelRef.current.classList.add("active");
    }

    try {
      onBlur && onBlur(e);
      if (isDate === true) {
        inputRef.current.type = "date";
      }
    } catch (onBlurError) {
      //
    }
  };

  return (
    <div className={`form-group`}>
      <div
        className={`
            ${isFloatingLabel ? "floating-label" : ""} 
            ${className ? className : ""}
            ${isDate ? "date-label-container" : ""}`}
        ref={labelRef}
      >
        {size !== "small" && label && <label htmlFor={label}>{label}</label>}
        <input
          type={isVisible ? "text" : type}
          className={`form-control ${
            size === "small" ? "form-control--sm" : ""
          }`}
          min={min}
          max={max}
          name={name}
          onChange={onChange}
          value={value}
          ref={inputRef}
          data-placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={onClick}
          autoComplete={value}
          placeholder={placeHolder}
          // disabled={isDisabled || disabled}
          inputMode={isDate ? "none" : ""}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
        {size !== "small" && (
          <span className="input-icon" id="toggle-icon">
            {type === "password" && (
              <Icon
                  id={isVisible ? "eye-icon" : "eye-close"}
                  width={isVisible ? "16" : "24"}
                  height={isVisible ? "15" : "24"}
                  onClick={() => setIsVisible((visible) => !visible)}
                />
              )}
              {suffixIcon && suffixIcon !== "" && (
                <Icon
                  id={suffixIcon}
                  width="24"
                  height="24"
                  />

              )}
            </span>
        )}
        {helperText && (
          <small className={`${isHelperText ? "helperText" : ""}`}>
            {helperText}
          </small>
        )}
      </div>
      {error}
    </div>
  );
};

export default Input;
