import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import DashboardSIdebar from "../components/DashboardSIdebar";
import Spinner from "../components/PageLoader";
import SidebarSlide from "../components/SlidingSidebar";
import DashboardHeader from "../components/DashboardHeader";
import { useSelector } from "react-redux";
import Icon from "../components/Icon";
import { navigations } from "../utils/navigations";
// import { useSelector, useDispatch } from "react-redux";
// import { getBusinessDetails } from "../features/user/userSlice";
// import Spinner from "../components/PageLoader";
// import { Toaster } from "react-hot-toast";

const DashboardLayout = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  //   const { isLoading } = useSelector((store) => store.user);
  //   const dispatch = useDispatch();
  const [slidingSidebar, setSlidingSidebar] = useState(false);
  const { user } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  //   useEffect(() => {
  //     dispatch(getBusinessDetails());
  //   }, []);

  useEffect(() => {
    if (!user?.data?.providerOnboarded) {
      navigate("/onboarding");
    }
  }, []);

  useEffect(() => {
    const notificationShown = localStorage.getItem("notificationShown");
    if (user?.data?.providerOnboarded && !notificationShown) {
      setShowNotification(true); 
    }
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
    localStorage.setItem("notificationShown", "true");
  };

  return (
    <>
      {false ? (
        <Spinner />
      ) : (
        <>
          {/* <div className="toaster__style">
            <Toaster position="top-right" reverseOrder={false} />
          </div> */}
          <SidebarSlide
            setIsSidebarOpen={setSlidingSidebar}
            isSidebarOpen={slidingSidebar}
            splitLocation={splitLocation}
          />
          <div className="dashboard">
            <DashboardSIdebar
              slidingSidebar={slidingSidebar}
              setSlidingSidebar={setSlidingSidebar}
              splitLocation={splitLocation}
            />
            <div className="dashboard__main">
              <DashboardHeader
                splitLocation={splitLocation}
                setSlidingSidebar={setSlidingSidebar}
                slidingSidebar={slidingSidebar}
              />
              <div className={`container ${showNotification ? "mt-30" : "mt-20"}`}>
                {showNotification && (
                  <div className="dashboard__notification__banner">

                      <span className="dashboard__notification__banner__icon">
                        <Icon id="lightbulb" width={24} height={24} />
                      </span>

                      <div className="dashboard__notification__banner__text__container">
                        <p className="dashboard__notification__banner__text">
                          To avoid missed payments, please verify the client's
                          passcode before granting access to your facility.
                        </p>

                        <span
                          onClick={handleCloseNotification}
                          className="dashboard__notification__banner__text__close"
                        >
                          Noted
                        </span>
                      </div>

                   
                  </div>
                )}

                <Outlet />
              </div>
              <div className="floating__navigation">
                <div className="floating__navigation__container">
                  {navigations.map((navigation) => (
                    <Link
                      key={navigation.id}
                      to={navigation.link}
                      className={`floating__navigation__container__item${
                        splitLocation[1] === navigation.navName ? "--active" : ""
                      }`}
                    >
                      <Icon
                        id={splitLocation[1] === navigation.navName 
                          ? navigation.floatingColoredIcon 
                          : navigation.floatingIcon
                        }
                        width={16}
                        height={16}
                      />
                      <p>
                        {navigation.name}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardLayout;
