import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Button from "../Button";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClient,
  getProviderSubscribers,
} from "../../features/clients/clientSlice";
import { useNavigate } from "react-router-dom";

const DeleteClientModal = ({ setShowDeleteModal, showDeleteModal, client }) => {
  const [storedData, setStoredData] = useState({});
  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  useEffect(() => {
    getFromLocalStorage();
  }, [storedData]);
  const { isDeleting } = useSelector((store) => store.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal
      handleClose={() => {
        setShowDeleteModal(false);
      }}
      isHeader={false}
      show={showDeleteModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalSmall={true}
      containerClassName="deltePlans"
    >
      <span>
        <Icon id={"waarning"} height={"64"} width={"64"} />
      </span>
      <h4 className="mt-4">Delete Client</h4>
      <p className="mt-2 co-gray">Deleting your client will permanently remove it</p>
      <div className="btn--container--small">
        <Button
          variant="secondary"
          type="submit"
          className="mt-4"
          block
          size={"md"}
          onClick={() => {
            setShowDeleteModal(false);
          }}
        >
          no, Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="mt-4"
          block
          size={"md"}
          onClick={() => {
            dispatch(
              deleteClient({
                id: client?.id,
                clientType: client?.clientType,
                branchId: storedData?.id,
              })
            ).then((res) => {
              if (res.type === "user/deleteClient/fulfilled") {
                setShowDeleteModal(false);
                dispatch(getProviderSubscribers());
                navigate("/clients");
              }
            });
          }}
        >
          {isDeleting ? "Deleting..." : "Yes, Delete Client"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteClientModal;
