import React, { useEffect, useState } from "react";
import TeamMembersTable from "../../components/table/TeamMembersTable";
import PaginationWithApi from "../../components/PaginationWithApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderBranch,
  getTeamMembers,
} from "../../features/user/userSlice";
import Spinner from "../../components/PageLoader";
import AddMember from "../../components/AddMember";
import Button from "../../components/Button";
import ChangeMemberRole from "../../components/ChangeMemberRole";
import DeleteMemberModal from "../../components/modal/DeleteMemberModal.";
import { hasPermission } from "../../utils/authHelpers";
import Icon from "../../components/Icon";

const RoleManagement = () => {
  const dispatch = useDispatch();
  const { teamMembers, isLoading, error } = useSelector((state) => state.user);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [activeTab, setActiveTab] = useState("members");
  const [currentPage, setCurrentPage] = useState(0);
  const [openChangeMemberRole, setOpenChangeMemberRole] = useState(false);
  const [selctedMembers, setSelctedMembers] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {user } = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;

  useEffect(() => {
    dispatch(getTeamMembers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProviderBranch());
  }, []);
  // Format the API response to match table structure
  const formattedData =
    teamMembers?.map((member, index) => ({
      id: member?.id,
      firstName: member.contactPerson?.firstName || "-",
      lastName: member.contactPerson?.lastName || "-",
      contact: member.email || member.contactPerson?.emailAddress || "--",
      userRole: member.role?.name?.replace(/_/g, " ") || "--",
    })) || [];

  const handleNext = () => {
    // Assuming you'll have pagination data from your API
    // if (!data?.last) {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    // }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  if (isLoading) return <Spinner />;

  const openChangeSideModal = (row) => {
    setSelctedMembers(row);
    setOpenChangeMemberRole(true);
  };
  const openDeleteModal = (row) => {
    console.log(row, ":row");
    setSelctedMembers(row);
    setShowDeleteModal(true);
  };

  return (
    <div className="rolemanagement">
      <div className="rolemanagement__header">
        {/*<div className="flex flex-row justify-between items-center w-full max-w-[408px] h-[40px] md:h-[56px] rounded-[40px] bg-[#F5F5F5] gap-[4px] p-[4px_5px]">
          <div
            className={`flex flex-row items-center justify-center gap-[8px] py-4 cursor-pointer w-full h-full text-[#979797] ${
              activeTab === "members" ? "bg-[#005397] text-white" : ""
            } rounded-[40px]`}
            onClick={() => setActiveTab("members")}
            role="button"
            tabIndex={0}
          >
            <Icon id="user-role" width={24} height={24} />
            <span className="text-[14px] md:text-[16px] font-semibold tracking-[0.02em]">
              TEAM MEMBERS
            </span>
          </div>
          <div
            className={`flex flex-row items-center justify-center gap-[8px] py-4 cursor-pointer w-full h-full text-[#979797] ${
              activeTab === "roles" ? "bg-[#005397] text-white" : ""
            } rounded-[40px]`}
            onClick={() => setActiveTab("roles")}
            role="button"
            tabIndex={0}
          >
            <Icon
              id={activeTab === "roles" ? "check-role" : "check-role-disabled"}
              width={20}
              height={20}
            />
            <span className="text-[14px] md:text-[16px] font-semibold tracking-[0.02em]">
              ROLES
            </span>
          </div>
        </div>
         */}
        <h4 className="text-[20px] font-[600] text-left text-[#000B14] leading-[32px] tracking-[0%]">
          Role Management
        </h4>
        <Button
          type="button"
          variant="primary"
          // className="btn--block"
          onClick={() => setOpenAddMember(true)}
        >
          + Add Team Members
        </Button>
      </div>

      <div className="rolemanagement__body">
        <div className="rolemanagement__body__members">
          <TeamMembersTable
            data={formattedData}
            setOpenChangeMemberRole={setOpenChangeMemberRole}
            setSelctedMembers={setSelctedMembers}
            setShowDeleteModal={setShowDeleteModal}
          />
          {formattedData?.map((item) => {
            const initials = `${item.firstName[0]}${item.lastName[0]}`;
            const roleClasses =
              item.userRole === "SUPER_ADMIN"
                ? "text-[#005397] bg-[rgba(0,83,151,0.1)]"
                : item.userRole === "ADMIN"
                ? "text-[#029AFF] bg-[rgba(2,154,255,0.1)]"
                : item.userRole === "MEMBER"
                ? "text-[#8500A2] bg-[rgba(184,6,224,0.1)]"
                : "text-[#3BAD54] bg-[rgba(59,173,84,0.1)]";

            return (
              <div
                className="flex md:hidden flex-col w-full justify-between items-center gap-[6px] p-4 rounded-[12px] border border-[#D3D3D399] mb-5"
                key={item.id}
              >
                <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-row justify-start items-center gap-[6px]">
                  <span
                    className={`w-[31px] h-[31px] rounded-full border border-[#FF8788] bg-[#FFF3F3] flex justify-center items-center `}
                  >
                    <span className="text-[#3D0814] font-[500] text-[12px]">
                      {initials}
                    </span>
                  </span>
                  <div className="flex flex-col justify-center items-start gap-[6px]">
                    <h5 className="text-[#000B14] text-[12px] font-[500]">{`${item.firstName} ${item.lastName}`}</h5>
                    <p className="text-[#5C6368] text-[10px] font-[400]">
                      {item.contact}
                    </p>
                  </div>
                </div>

                <span
                  className={`min-w-[96px] h-[28px] rounded-[100px] py-2 px-3 flex justify-center items-center ${roleClasses}`}
                >
                  <span className="text-[10px] font-[600] tracking-[2%] uppercase">
                    {item.userRole}
                  </span>
                </span>
                </div>

                <div className="flex flex-row w-full justify-between items-center">
                  {hasPermission(userRole, "ADMIN") && (
                    <td>
                      {/* <span
              className={`status ${
                row?.userRole === "SUPER_ADMIN"
                  ? "member-SA"
                  : row?.userRole === "ADMIN"
                  ? "member-A"
                  : row?.userRole === "MEMBER"
                  ? "member-M"
                  : row?.userRole === "OPERATOR"
                  ? "member-O"
                  : "pending"
              }`}
            >
              {row?.userRole}
            </span> */}
                      <div>
                        <Button
                          variant="secondary"
                          type="submit"
                          className="change"
                          disabled={item?.userRole === "SUPER ADMIN"}
                          size={"sm"}
                          onClick={() => openChangeSideModal(item)}
                        >
                          <div className="flex flex-row items-center gap-[2px]">
                            <Icon id="smPerson" width={16} height={16} />
                            <span className="text-[10px] underline">
                              {"Change Role"}
                            </span>
                          </div>
                        </Button>
                      </div>
                    </td>
                  )}
                  {hasPermission(userRole, "ADMIN") && (
                    <td>
                      <div>
                        <Button
                          variant="secondary"
                          type="submit"
                          className="change"
                          size={"sm"}
                          disabled={item?.userRole === "SUPER ADMIN"}
                          onClick={() => openDeleteModal(item)}
                        >
                          <div className="flex flex-row items-center gap-[2px]">
                            <Icon id="removePerson" width={16} height={16} />
                            <span className="text-[10px] underline">
                              {"Remove member"}
                            </span>
                          </div>
                        </Button>
                      </div>
                    </td>
                  )}
                </div>
              </div>
            );
          })}
          <div className="hidden md:block w-full">
            <PaginationWithApi
              setCurrentPage={setCurrentPage}
              data={{ content: formattedData }} // Replace with your API response data
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          </div>
        </div>
        {openAddMember && (
          <AddMember
            openAddMember={openAddMember}
            setOpenAddMember={setOpenAddMember}
          />
        )}

        {openChangeMemberRole && (
          <ChangeMemberRole
            openChangeMemberRole={openChangeMemberRole}
            setOpenChangeMemberRole={setOpenChangeMemberRole}
            selctedMembers={selctedMembers}
          />
        )}

        {showDeleteModal && (
          <DeleteMemberModal
            selctedMembers={selctedMembers}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
          />
        )}

        {/*activeTab === "roles" && (
          <>
            <div className="border border-[#F2F6F5] rounded-[15px] w-full">
              <div className="w-full p-[12px_20px] border-b border-[#F2F6F5] rounded-t-[15px] bg-[#F5F5F5]">
                <h4 className="text-[14px] font-semibold text-left text-[#000B14] tracking-[0.02em] leading-[150%] uppercase">
                  OPERATOR ROLE PERMISSIONS
                </h4>
              </div>
              <div className="w-full hidden md:block">
              <div
                    
                    className={`grid grid-cols-4 border-t border-[#F2F6F5] border-t-[#F2F6F5]`}
                  >
                {permissions.map((permission, index) => (
                  
                    <div className="flex flex-row p-[20px_20px] border-t border-[#F2F6F5] items-center justify-start gap-[8px] cursor-not-allowed">
                      <Icon id="check-role-disabled" width={20} height={20} />
                      <span className="text-[14px] font-normal text-left text-[#5C6368] leading-[150%]">
                        {permission.name}
                      </span>
                    
                  </div>
                ))}
                </div>
              </div>
              <div className="w-full md:hidden flex flex-row flex-wrap gap-[12px] py-3 px-5">
                {permissions.map((permission) => (
                  <div
                    key={permission.id}
                    className="flex flex-row items-center justify-start gap-[8px] cursor-not-allowed"
                  >
                    <Icon id="check-role-disabled" width={20} height={20} />
                    <span className="text-[14px] font-normal text-left text-[#5C6368] leading-[150%]">
                      {permission.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )*/}
      </div>
    </div>
  );
};

export default RoleManagement;
