import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";

import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../components/forms";
import {
  fetchImageLink,
  getProviderDetails,
  toggleOnlinePayment,
  togglePaymentRecipt,
  toggleRemindersForAutoRenewal,
  updateProviderLogo,
} from "../../features/user/userSlice";
import Icon from "../../components/Icon";
// import { getProviderImage } from "../../features/user/userSlice";
import toast from "react-hot-toast";
import axios from "axios";
import nprogress from "nprogress";

const Account = () => {
  const {
    user,
    providerData,
    loadingReceipt,
    loadingOnlineToggle,
    loadingExpiry,
    userImgLink,
  } = useSelector((store) => store.user);
  const [sendPaymentReceipt, setSendPaymentReceipt] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewProfile, setPreviewProfile] = useState("");
  const [renewalReminders, setRenewalReminders] = useState({
    oneDay: false,
    twoDays: false,
    oneWeek: false,
    expired: false,
  });
  const [onlinePayment, setOnlinePayment] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderDetails());
    dispatch(fetchImageLink());
  }, [
    sendPaymentReceipt,
    onlinePayment,
    renewalReminders.oneWeek,
    renewalReminders.oneDay,
    renewalReminders.twoDays,
  ]);

  useEffect(() => {
    setSendPaymentReceipt(providerData?.sendReceipt);
  }, [providerData?.sendReceipt, sendPaymentReceipt]);

  useEffect(() => {
    setOnlinePayment(providerData?.acceptOnlinePayments);
  }, [providerData?.acceptOnlinePayments, onlinePayment]);

  useEffect(() => {
    setRenewalReminders({
      oneDay: providerData?.autoRenewalDays?.includes(1) ? true : false,
      twoDays: providerData?.autoRenewalDays?.includes(2) ? true : false,
      oneWeek: providerData?.autoRenewalDays?.includes(7) ? true : false,
      expired: providerData?.autoRenewalDays?.includes(-1) ? true : false,
    });
  }, [
    providerData?.autoRenewalDays?.length,
    renewalReminders.oneWeek,
    renewalReminders.oneDay,
    renewalReminders.twoDays,
  ]);

  const handleDaysToExpiry = (days) => {
    const daysNotification = [...providerData?.autoRenewalDays];
    if (daysNotification?.includes(days)) {
      const newPayload = daysNotification.filter((item) => item !== days);
      dispatch(toggleRemindersForAutoRenewal(newPayload)).then((res) => {
        if (res?.type === "user/toggleRemindersForAutoRenewal/fulfilled") {
          dispatch(getProviderDetails());
        }
      });
    } else {
      const newPayload = [...daysNotification, days];
      dispatch(toggleRemindersForAutoRenewal(newPayload)).then((res) => {
        if (res?.type === "user/toggleRemindersForAutoRenewal/fulfilled") {
          dispatch(getProviderDetails());
        }
      });
    }
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadToAws(file, -1);
    }
  };
  const uploadToAws = async (fileUpload) => {
    nprogress.start();
    setUploadingImage(true);
    try {
      const res = await axios.put(userImgLink, fileUpload, {
        headers: {
          "Content-Type": fileUpload.type,
        },
      });
      const result = res?.config?.url;
      nprogress.done();
      setPreviewProfile(removeQueryString(result));
      dispatch(updateProviderLogo(removeQueryString(result)));
      setUploadingImage(false);
    } catch (error) {
      nprogress.done();
      setUploadingImage(false);
      toast.error("Error uploading image");
      console.error("Error uploading image", error);
    }
  };
  function removeQueryString(url) {
    return url.split("?")[0];
  }
  return (
    <div className="account">
      <div className="account__header">
        <div className="account__header--img">
          <div className="img__container">
            <img
              src={
                previewProfile
                  ? previewProfile
                  : providerData?.providerLogo || ""
              }
              alt=""
            />
          </div>
          <div className="img__text">
            <h4>{providerData?.providerName}</h4>
            <p>{providerData?.email}</p>
          </div>
        </div>
        <div className="account__header--btn">
          <Button
            variant="primary"
            type="submit"
            className="change"
            block
            size={"sm"}
            onClick={handleButtonClick}
          >
            {uploadingImage ? "uploading..." : "Change Logo"}
          </Button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />
          <p>Recommended size is 100x100px</p>
        </div>
      </div>

      <div className="account__body">
        <h3>Business information</h3>
        <div className="account__body--deets">
          <div className="inputgroup mt-6">
            <Input
              type="text"
              id="businessName"
              label="Business Name"
              name="businessName"
              disabled
              value={providerData?.providerName}
              placeholder="Business Name"
            />
            <Input
              type="text"
              id="email"
              label="Email address"
              name="email"
              disabled
              value={user?.data?.email}
              //   onChange={handleChange}
              placeholder="Email address"
            />
          </div>
          <div className="inputgroup">
            <Input
              type="text"
              id="serviceCategory"
              label="Service Category"
              name="serviceCategory"
              disabled
              value={"Gym"}
              //   onChange={handleChange}
              placeholder="Service Category"
            />
            <Input
              type="text"
              id="phone"
              label="Phone Number"
              name="phone"
              disabled
              value={user?.data?.phone}
              //   onChange={handleChange}
              placeholder="Phone Number"
            />
          </div>
        </div>
      </div>
      <div className="account__body">
        <h3>Notification Settings</h3>
        <div className="account__body--container account__body--bg">
          <div className="left">
            <div className="showOnFlance">
              <div className="toggle--container">
                {loadingReceipt && <div className="mini-loader"></div>}
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name={`sendPaymentReceipt`}
                    id={`sendPaymentReceipt`}
                    checked={sendPaymentReceipt}
                    onChange={() => {
                      dispatch(togglePaymentRecipt()).then((res) => {
                        if (
                          res?.type === "user/togglePaymentRecipt/fulfilled"
                        ) {
                          setSendPaymentReceipt(!sendPaymentReceipt);
                        }
                      });
                    }}
                  />
                  <label className="label" htmlFor={`sendPaymentReceipt`}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="right ">
            <p className="rightText">
              Send Payment receipt{" "}
              <span>A charge of 5 Naira will apply for each receipt sent</span>
            </p>
            <p className="rightText--btm">
              Automatically send digitals receipts to clients
            </p>
          </div>
        </div>
        <div className="account__body--container account__body--sm">
          <div className="sm--container">
            <div className="left">
              <div className="showOnFlance">
                <div className="toggle--container">
                  {loadingReceipt && <div className="mini-loader"></div>}
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name={`sendPaymentReceipt`}
                      id={`sendPaymentReceipt`}
                      checked={sendPaymentReceipt}
                      onChange={() => {
                        dispatch(togglePaymentRecipt()).then((res) => {
                          if (
                            res?.type === "user/togglePaymentRecipt/fulfilled"
                          ) {
                            setSendPaymentReceipt(!sendPaymentReceipt);
                          }
                        });
                      }}
                    />
                    <label className="label" htmlFor={`sendPaymentReceipt`}>
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="right ">
              <p className="rightText">Send Payment receipt </p>
            </div>
          </div>

          <p className="rightText">
            <span>A charge of 5 Naira will apply for each receipt sent</span>
          </p>
          <p className="rightText--btm">
            Automatically send digitals receipts to clients
          </p>
        </div>
        <div className="account__body--container">
          <div className="w-full">
            <p className="rightText">
              Enable Renewal Reminders{" "}
              <span>A charge of 5 Naira will apply for each reminder sent</span>
            </p>
            <p className="rightText--btm">
              Automatically notify clients to renew before their subscription
              ends.
            </p>
            {loadingExpiry && <div className="mini-loader"></div>}
            <div className="duration-toggle">
              <div className="content">
                <div className="toggle--container">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name={`daysToExpiriy`}
                      id={`daysToExpiry1`}
                      checked={renewalReminders.oneDay}
                      onChange={() => handleDaysToExpiry(1)}
                    />
                    <label className="label" htmlFor={`daysToExpiry1`}>
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                  A day to expiry
                </div>
                {/* <div className="options--container">
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="sms" width={20} height={20} />
                      <span>SMS</span>
                    </div>
                  </div>
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="email@" width={20} height={20} />
                      <span>Email</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="content">
                <div className="toggle--container">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name={`daysToExpiriy`}
                      id={`daysToExpiry2`}
                      checked={renewalReminders.twoDays}
                      onChange={() => handleDaysToExpiry(2)}
                    />
                    <label className="label" htmlFor={`daysToExpiry2`}>
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                  2 days to expiry
                </div>
                {/* <div className="options--container">
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="sms" width={20} height={20} />
                      <span>SMS</span>
                    </div>
                  </div>
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="email@" width={20} height={20} />
                      <span>Email</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="content">
                <div className="toggle--container">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name={`daysToExpiriy`}
                      id={`daysToExpiry7`}
                      checked={renewalReminders.oneWeek}
                      onChange={() => handleDaysToExpiry(7)}
                    />
                    <label className="label" htmlFor={`daysToExpiry7`}>
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                  A week to expiry
                </div>
                {/* <div className="options--container">
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="sms" width={20} height={20} />
                      <span>SMS</span>
                    </div>
                  </div>
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="email@" width={20} height={20} />
                      <span>Email</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="content">
                <div className="toggle--container">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name={`daysToExpiriy`}
                      id={`expired`}
                      checked={renewalReminders.expired}
                      onChange={() => handleDaysToExpiry(-1)}
                    />
                    <label className="label" htmlFor={`expired`}>
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                  A day after expiry
                </div>
                {/* <div className="options--container">
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="sms" width={20} height={20} />
                      <span>SMS</span>
                    </div>
                  </div>
                  <div className="option">
                    <input type="checkbox" />
                    <div className="item">
                      <Icon id="email@" width={20} height={20} />
                      <span>Email</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="account__body">
        <h3>Online Payments</h3>
        <div className="account__body--container">
          <div className="left">
            <div className="showOnFlance">
              <div className="toggle--container">
                {loadingOnlineToggle && <div className="mini-loader"></div>}
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name={`onlinePayment`}
                    id={`onlinePayment`}
                    checked={onlinePayment}
                    onChange={() => {
                      dispatch(toggleOnlinePayment()).then((res) => {
                        if (
                          res?.type === "user/toggleOnlinePayment/fulfilled"
                        ) {
                          setOnlinePayment(!onlinePayment);
                        }
                      });
                    }}
                  />
                  <label className="label" htmlFor={`onlinePayment`}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <p className="rightText">
              Accept Payments Online{" "}
              <span>
                A 2% fee is applied to each payment processed, capped at 4,000
                Naira per transaction
              </span>
            </p>
            <p className="rightText--btm">
              Simplify payments by sending a link for your clients to pay
              online. Payments are automatically added to your Flance balance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
