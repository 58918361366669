import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProviderAccountBranch } from "../../features/user/userSlice";
import Spinner from "../../components/PageLoader";

const Branch = () => {
  const dispatch = useDispatch();
  const { providerAccountBranches, isLoading } = useSelector(
    (store) => store.user
  );
  const [selectedBranch, setSelectedBranch] = useState(null);

  useEffect(() => {
    dispatch(getProviderAccountBranch());
  }, []);

  const parseJSONSafely = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const renderListItems = (items, noItemsMessage) =>
    items && items.length > 0 ? (
      items.map((item, index) => (
        <div
          className="w-full flex flex-row justify-start items-start gap-[8px] py-[8px]"
          key={index}
        >
          <Icon id="check-role-disabled" width={20} height={20} />
          <span className="text-[14px] font-[400] text-left text-[#5C6368] leading-[150%] tracking-[0%]">
            {item}
          </span>
        </div>
      ))
    ) : (
      <div className="text-[14px] font-[400] text-left text-[#5C6368] leading-[150%] tracking-[0%]">
        {noItemsMessage}
      </div>
    );

  const renderOpeningHours = (operationHours) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    if (!operationHours) {
      return days.map((day, index) => (
        <div
          className="w-full flex flex-row justify-start items-center md:items-start gap-[8px] py-[8px]"
          key={index}
        >
          <span className="text-[14px] w-[78px] font-[400] text-left text-[#344054] tracking-[0%]">
            {day}
          </span>
          <span className="text-[14px] w-[65px] font-[400] text-left text-[#000B14] tracking-[0%]">
            --
          </span>
        </div>
      ));
    }

    try {
      const parsedHours = JSON.parse(operationHours);
      const renderedHours = parsedHours.map(({ time, day }, index) => (
        <div
          className="w-full flex flex-row justify-start items-center md:items-start gap-[8px] py-[8px]"
          key={index}
        >
          <span className="text-[14px] w-[78px] font-[400] text-left text-[#344054] tracking-[0%]">
            {day}
          </span>
          <span className="text-[14px] w-[65px] font-[400] text-left text-[#000B14] tracking-[0%]">
            {time}
          </span>
        </div>
      ));

      const missingDays = days.filter(
        (day) => !parsedHours.some((hour) => hour.day.includes(day))
      );

      const missingHours = missingDays.map((day, index) => (
        <div
          className="w-full flex flex-row justify-start items-center md:items-start gap-[8px] py-[8px]"
          key={`missing-${index}`}
        >
          <span className="text-[14px] w-[78px] font-[400] text-left text-[#344054] tracking-[0%]">
            {day}
          </span>
          <span className="text-[14px] w-[65px] font-[400] text-left text-[#000B14] tracking-[0%]">
            --
          </span>
        </div>
      ));

      return [...renderedHours, ...missingHours];
    } catch (error) {
      console.error("Error parsing OperationHours:", error);
      return days.map((day, index) => (
        <div
          className="w-full flex flex-row justify-start items-center md:items-start gap-[8px] py-[8px]"
          key={index}
        >
          <span className="text-[14px] w-[78px] font-[400] text-left text-[#344054] tracking-[0%]">
            {day}
          </span>
          <span className="text-[14px] w-[65px] font-[400] text-left text-[#000B14] tracking-[0%]">
            --
          </span>
        </div>
      ));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="branch">
      <div className="flex w-full flex-col justify-start pt-5 items-start gap-[30px]">
        {providerAccountBranches?.data?.length === 1 ? (
          <>
            <h4 className="text-[20px] font-[600] text-left text-[#000B14] leading-[32px] tracking-[0%]">
              Branch Details
            </h4>
            {Array.isArray(providerAccountBranches.data) &&
              providerAccountBranches.data.map((branch) => (
                <div
                  key={branch.id}
                  className="flex w-full flex-col justify-start items-start gap-8"
                >
                  {/* Equipment */}
                  <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                    <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                      <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                        Equipment
                      </span>
                      <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                        {renderListItems(
                          parseJSONSafely(branch.details)?.Equipment?.split(
                            ", "
                          ),
                          "No equipment found"
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Amenities */}
                  <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                    <div className="flex w-full flex-col justify-start items-start gap-[16px]">
                      <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                        Amenities
                      </span>
                      <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                        {renderListItems(
                          parseJSONSafely(branch.details)?.Amenities?.split(
                            ", "
                          ),
                          "No amenities found"
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Activities */}
                  <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                    <div className="flex w-full flex-col justify-start items-start gap-[16px]">
                      <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                        Activities
                      </span>
                      <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                        {renderListItems(
                          parseJSONSafely(
                            branch.details
                          )?.ClassesOffered?.split(", "),
                          "No activities found"
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Opening Hours */}
                  <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                    <div className="flex w-full flex-col justify-start items-start gap-[16px]">
                      <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                        Opening Hours
                      </span>
                      <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                        {renderOpeningHours(branch.details.OperationHours)}
                      </div>
                    </div>
                  </div>

                  {/* Images */}
                  <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                    <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                      <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                        Images
                      </span>
                      {branch.photos.length > 0 ? (
                        <div className="flex w-full flex-col md:flex-row justify-start items-start gap-[20px]">
                          {branch.photos.map((photo, index) => (
                            <img
                              key={index}
                              src={photo}
                              alt={`branch-img-${index + 1}`}
                              className="w-full md:max-w-[calc(50%-20px)] h-[176px] md:h-[350px]"
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="text-[14px] font-[400] text-left text-[#5C6368] leading-[150%] tracking-[0%]">
                          No images found for this branch.
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Contact */}
                  <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                    <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                      <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                        Contact
                      </span>
                      <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                        <div className="flex w-full flex-col justify-start items-start gap-[8px]">
                          <label
                            htmlFor="phone"
                            className="text-[14px] font-[500] text-left text-[#344054] leading-[24px]"
                          >
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={branch.contactPhoneNumbers || "--"}
                            className="w-full px-[30px] py-5 text-[#5C6368] bg-[#F5F5F5] border border-[#D3D3D399] rounded-[12px] focus:outline-none focus:ring-[#D0D5DD] focus:border-[#D0D5DD] disabled:bg-[#F2F4F7] disabled:text-[#667085] disabled:cursor-not-allowed"
                            disabled
                          />
                        </div>
                        <div className="flex w-full flex-col justify-start items-start gap-[8px]">
                          <label
                            htmlFor="instagram-link"
                            className="text-[14px] font-[500] text-left text-[#344054] leading-[24px]"
                          >
                            Instagram link
                          </label>
                          <input
                            type="text"
                            id="instagram-link"
                            name="instagram-link"
                            value={
                              parseJSONSafely(branch.details)
                                ?.SocialMediaHandles?.split(", ")
                                ?.find((handle) => handle.includes("Instagram"))
                                ?.replace(/^[^@]*/, "") || "--"
                            }
                            className="w-full px-[30px] py-5 text-[#5C6368] bg-[#F5F5F5] border border-[#D3D3D399] rounded-[12px] focus:outline-none focus:ring-[#D0D5DD] focus:border-[#D0D5DD] disabled:bg-[#F2F4F7] disabled:text-[#667085] disabled:cursor-not-allowed"
                            disabled
                          />
                        </div>
                        <div className="flex w-full flex-col justify-start items-start gap-[8px]">
                          <label
                            htmlFor="website-link"
                            className="text-[14px] font-[500] text-left text-[#344054] leading-[24px]"
                          >
                            Website link
                          </label>
                          <input
                            type="text"
                            id="website-link"
                            name="website-link"
                            value={branch.website || "--"}
                            className="w-full px-[30px] py-5 text-[#5C6368] bg-[#F5F5F5] border border-[#D3D3D399] rounded-[12px] focus:outline-none focus:ring-[#D0D5DD] focus:border-[#D0D5DD] disabled:bg-[#F2F4F7] disabled:text-[#667085] disabled:cursor-not-allowed"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            <div className="flex flex-col justify-start items-start gap-[6px]">
              <h4 className="text-[20px] font-[600] text-left text-[#000B14] leading-[32px] tracking-[0%]">
                Business Branch
              </h4>
              <span className="text-[14px] font-[400] text-left text-[#5C6368] leading-[28px] tracking-[0%]">
                For businesses that operate from a multiple locations. (this
                allows you to manage all branches)
              </span>
            </div>
            <div className="flex w-full flex-col justify-start items-start gap-[30px] rounded-[12px]">
              {Array.isArray(providerAccountBranches.data) &&
                providerAccountBranches.data.map((branch) => (
                  <div
                    key={branch.id}
                    className={`w-full rounded-[12px] ${
                      selectedBranch === branch.id
                        ? "border border-[#D3D3D399]"
                        : ""
                    }`}
                  >
                    <div
                      onClick={() => {
                        setSelectedBranch(
                          branch.id === selectedBranch ? null : branch.id
                        );
                      }}
                      className="flex cursor-pointer w-full group h-16 bg-[#F5F5F5] px-[30px] py-5 justify-between items-center rounded-t-[12px]"
                    >
                      <div className="flex flex-row justify-start items-start gap-[6px]">
                        <h5 className="text-[20px] font-[600] text-left text-[#344054] leading-[24px] tracking-[0%]">
                          {branch.name}
                        </h5>
                        <span className="text-[20px] font-[400] text-left text-[#5C6368] leading-[24px] tracking-[0%]">
                          {branch.address.street}, {branch.address.city}
                        </span>
                      </div>
                      <span
                        className={` hidden sm:block ${
                          selectedBranch === branch.id
                            ? "group-hover:-translate-y-1"
                            : "group-hover:translate-y-1"
                        } transition-transform duration-200`}
                      >
                        <Icon
                          id={
                            selectedBranch === branch.id
                              ? "branch-arrow-up"
                              : "branch-arrow-down"
                          }
                          width={16}
                          height={8}
                        />
                      </span>
                      <span
                        className={`block sm:hidden group-hover:translate-x-1 transition-transform duration-200`}
                      >
                        <Icon id={"branch-arrow-right"} width={8} height={16} />
                      </span>
                    </div>

                    {selectedBranch === branch.id && (
                      <div
                        key={branch.id}
                        className="flex w-full flex-col justify-start items-start gap-8 p-[30px]"
                      >
                        {/* Equipment */}
                        <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                          <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                            <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                              Equipment
                            </span>
                            <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                              {renderListItems(
                                parseJSONSafely(
                                  branch.details
                                )?.Equipment?.split(", "),
                                "No equipment found"
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Amenities */}
                        <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                          <div className="flex w-full flex-col justify-start items-start gap-[16px]">
                            <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                              Amenities
                            </span>
                            <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                              {renderListItems(
                                parseJSONSafely(
                                  branch.details
                                )?.Amenities?.split(", "),
                                "No amenities found"
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Activities */}
                        <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                          <div className="flex w-full flex-col justify-start items-start gap-[16px]">
                            <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                              Activities
                            </span>
                            <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                              {renderListItems(
                                parseJSONSafely(
                                  branch.details
                                )?.ClassesOffered?.split(", "),
                                "No activities found"
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Opening Hours */}
                        <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                          <div className="flex w-full flex-col justify-start items-start gap-[16px]">
                            <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                              Opening Hours
                            </span>
                            <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                              {renderOpeningHours(
                                branch.details.OperationHours
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Images */}
                        <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                          <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                            <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                              Images
                            </span>
                            {branch.photos.length > 0 ? (
                              <div className="flex w-full flex-col md:flex-row justify-start items-start gap-[20px]">
                                {branch.photos.map((photo, index) => (
                                  <img
                                    key={index}
                                    src={photo}
                                    alt={`branch-img-${index + 1}`}
                                    className="w-full md:max-w-[calc(50%-20px)] h-[176px] md:h-[350px]"
                                  />
                                ))}
                              </div>
                            ) : (
                              <div className="text-[14px] font-[400] text-left text-[#5C6368] leading-[150%] tracking-[0%]">
                                No images found for this branch.
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Contact */}
                        <div className="w-full p-[30px] border border-[#D3D3D399] rounded-[15px]">
                          <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                            <span className="text-[20px] font-[600] text-left text-[#181413] leading-[28px] tracking-[0%]">
                              Contact
                            </span>
                            <div className="flex w-full flex-col justify-start items-start gap-[12px]">
                              <div className="flex w-full flex-col justify-start items-start gap-[8px]">
                                <label
                                  htmlFor="phone"
                                  className="text-[14px] font-[500] text-left text-[#344054] leading-[24px]"
                                >
                                  Phone Number
                                </label>
                                <input
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  value={branch.contactPhoneNumbers || "--"}
                                  className="w-full px-[30px] py-5 text-[#5C6368] bg-[#F5F5F5] border border-[#D3D3D399] rounded-[12px] focus:outline-none focus:ring-[#D0D5DD] focus:border-[#D0D5DD] disabled:bg-[#F2F4F7] disabled:text-[#667085] disabled:cursor-not-allowed"
                                  disabled
                                />
                              </div>
                              <div className="flex w-full flex-col justify-start items-start gap-[8px]">
                                <label
                                  htmlFor="instagram-link"
                                  className="text-[14px] font-[500] text-left text-[#344054] leading-[24px]"
                                >
                                  Instagram link
                                </label>
                                <input
                                  type="text"
                                  id="instagram-link"
                                  name="instagram-link"
                                  value={
                                    parseJSONSafely(branch.details)
                                      ?.SocialMediaHandles?.split(", ")
                                      ?.find((handle) =>
                                        handle.includes("Instagram")
                                      )
                                      ?.replace(/^[^@]*/, "") || "--"
                                  }
                                  className="w-full px-[30px] py-5 text-[#5C6368] bg-[#F5F5F5] border border-[#D3D3D399] rounded-[12px] focus:outline-none focus:ring-[#D0D5DD] focus:border-[#D0D5DD] disabled:bg-[#F2F4F7] disabled:text-[#667085] disabled:cursor-not-allowed"
                                  disabled
                                />
                              </div>
                              <div className="flex w-full flex-col justify-start items-start gap-[8px]">
                                <label
                                  htmlFor="website-link"
                                  className="text-[14px] font-[500] text-left text-[#344054] leading-[24px]"
                                >
                                  Website link
                                </label>
                                <input
                                  type="text"
                                  id="website-link"
                                  name="website-link"
                                  value={branch.website || "--"}
                                  className="w-full px-[30px] py-5 text-[#5C6368] bg-[#F5F5F5] border border-[#D3D3D399] rounded-[12px] focus:outline-none focus:ring-[#D0D5DD] focus:border-[#D0D5DD] disabled:bg-[#F2F4F7] disabled:text-[#667085] disabled:cursor-not-allowed"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Branch;
