import React, { useEffect, useState } from "react";
import {
  changeNavtitle,
  fetchProviderBenefit,
  getProviderCredit,
} from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import PlanPriceModal from "../../components/modal/PlanPriceModal";
import Button from "../../components/Button";
import NewPlanModal from "../../components/modal/AddNewlanModal";
import Icon from "../../components/Icon";
import DeletePlanModal from "../../components/modal/DeletePlanModal";
import Spinner from "../../components/PageLoader";
import { hasPermission } from "../../utils/authHelpers";

const Plan = () => {
  const dispatch = useDispatch();
  const { providerBenefit, isLoading } = useSelector((store) => store.user);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [plan, setPlan] = useState({});
  const [createPlan, setCreatePLan] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { user } = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;
  const [storedData, setStoredData] = useState({});


  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  useEffect(() => {
    getFromLocalStorage();
  }, [storedData]);

  useEffect(() => {
    dispatch(changeNavtitle("Plan Price"));
    dispatch(getProviderCredit());
  }, []);

  useEffect(() => {
    dispatch(fetchProviderBenefit());
  }, [storedData?.id]);


  return (
    <div className="plan">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="planPricing">
          <>
            {" "}
            {providerBenefit?.map((plan, index) => (
              <>
                <div className="plan__container" key={index}>
                  <div className="plan__name">
                    <span>{index + 1}.</span>
                    <p>{plan?.benefitName?.slice(0, 35)}{plan?.benefitName?.length > 35 ? '...' : ''}</p>
                  </div>
                  <div
                    className="plan__details"
                    onClick={() => {
                      setShowPriceModal(true);
                      setPlan(plan);
                    }}
                  >
                    <p>SEE DETAILS</p>
                    <div className="sm--details">
                      <Icon id={"rightArrow"} height={"24"} width={"24"} />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
          {hasPermission(userRole, "BRANCH_MANAGER") && (
            <Button
              variant="primary"
              type="submit"
              className="mt-6"
              block
              size={"md"}
              onClick={() => {
                setCreatePLan(true);
              }}
            >
              + Add New Plan
            </Button>
          )}
        </div>
      )}
      {showPriceModal && (
        <PlanPriceModal
          setShowPriceModal={setShowPriceModal}
          showPriceModal={showPriceModal}
          plan={plan}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {createPlan && (
        <NewPlanModal createPlan={createPlan} setCreatePLan={setCreatePLan} />
      )}
      {showDeleteModal && (
        <DeletePlanModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          plan={plan}
        />
      )}
    </div>
  );
};

export default Plan;
