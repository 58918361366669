import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { useNavigate } from "react-router-dom";
import SearchAddContainer from "../../components/SearchAddContainer";
import MemberTable from "../../components/table/MemberTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientStatus,
  sendClientRenewalMail,
  sendClientRenewalSms,
  sendClientsRenewalMail,
  sendClientsRenewalSms,
} from "../../features/clients/clientSlice";
import RenewNotificationModal from "../../components/modal/RenewNotificationModal";
import MobileTable from "../../components/table/MobileTable";
import { dateFormat, fmtCurrency } from "../../utils/helper";
import { getNotificationPricing } from "../../features/user/userSlice";
import WarningNotificationModal from "../../components/modal/WarningNotificationModal";
import ActiveBenefits from "../../components/ActiveBenefits";
import NoList from "../../assets/svgs/nolist.svg";

const Membership = () => {
  const navigate = useNavigate();
  const [searchMembers, setSearchMembers] = useState("");
  const [renewalNotificationModal, setRenewalNotificationModal] =
    useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const { clientStatus, isLoading } = useSelector((store) => store.client);
  const { notificationPricing } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [clientToSendMail, setClientToSendMail] = useState(null);
  const [isEmail, setIsEmail] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [storedData, setStoredData] = useState({});
  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  useEffect(() => {
    getFromLocalStorage();
  }, [storedData]);

  useEffect(() => {
    if (storedData?.id) {
      dispatch(getClientStatus({ branch: storedData?.id }));
    }
    dispatch(getNotificationPricing());
  }, [storedData?.id]);

  useEffect(() => {
    setRows(clientStatus.map((item) => ({ ...item, isChecked: false })));
  }, [clientStatus]);

  const handleCheck = (id) => {
    const updatedRows = rows.map((row) =>
      row.clientId === id ? { ...row, isChecked: !row.isChecked } : row
    );
    setRows(updatedRows);
    const allChecked = updatedRows.every((row) => row.isChecked);
    setCheckAll(allChecked);
  };

  const checkedCount = rows.filter((row) => row.isChecked);

  const handleCheckAll = () => {
    const newCheckAll = !checkAll;
    setCheckAll(newCheckAll);
    const updatedRows = rows.map((row) => ({ ...row, isChecked: newCheckAll }));
    setRows(updatedRows);
  };

  const unCheckBoxes = () => {
    const updatedRows = rows.map((row) => ({ ...row, isChecked: false }));
    setRows(updatedRows);
  };

  const handleMailClickHover = (id) => {
    setRenewalNotificationModal(true);
    setClientToSendMail(id);
  };

  const handleRenew = () => {
    if (checkedCount < 1) {
      const payload = {
        clientId: clientToSendMail?.clientId,
        clientType: clientToSendMail?.clientType,
        customizations: {
          title: "Benefit Renewal",
          logo: "https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png",
        },
        redirectUrl: "https://www.flanceapp.com",
        providerBranchId: storedData?.id,
      };
      const smsPayload = {
        clientId: clientToSendMail?.clientId,
        clientType: clientToSendMail?.clientType,
        providerBranchId: storedData?.id,
      };
      if (isEmail) {
        dispatch(sendClientRenewalMail(payload)).then((res) => {
          if (res.type === "user/sendClientRenewalMail/fulfilled") {
            setRenewalNotificationModal(false);
          }
        });
      } else {
        dispatch(sendClientRenewalSms(smsPayload)).then((res) => {
          if (res.type === "user/sendClientRenewalSms/fulfilled") {
            setRenewalNotificationModal(false);
          }
        });
      }
    } else {
      const payload = {
        clientIds: checkedCount.map((item) => item?.clientId),
        customizations: {
          title: "Benefit Renewal",
          logo: "https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png",
        },
        redirectUrl: "https://www.flanceapp.com",

        providerBranchId: storedData?.id,
      };
      const smsPayload = {
        clientIds: checkedCount.map((item) => item?.clientId),
        providerBranchId: storedData?.id,
      };
      if (isEmail) {
        dispatch(sendClientsRenewalMail(payload)).then((res) => {
          if (res.type === "user/sendClientsRenewalMail/fulfilled") {
            setRenewalNotificationModal(false);
          }
        });
      } else {
        dispatch(sendClientsRenewalSms(smsPayload)).then((res) => {
          if (res.type === "user/sendClientsRenewalSms/fulfilled") {
            setRenewalNotificationModal(false);
          }
        });
      }
    }
  };

  return (
    <>
      <div className="member">
        <p
          className="prev-btn"
          onClick={() => {
            navigate("/");
          }}
        >
          <Icon id="back" width="14" height="14" className="back-icon" />
          Back to Home
        </p>{" "}
        <div className="member__header">
          <div className="member__header--title">
            <h2>Membership Expiring</h2>
          </div>
          <div className="member__header--filter">
            <div className="member__header--left"></div>
            {/* <div className="transactions__header--right">
              <SearchAddContainer
                searchInput={searchMembers}
                changeInput={(e) => setSearchMembers(e.target.value)}
              />
            </div> */}
          </div>
        </div>
        <div className="member__body">
          <div className="member__table">
            <div className="table-container">
              {rows?.length > 0 ? (
                <>
                  <MemberTable
                    data={rows}
                    setRenewalNotificationModal={setRenewalNotificationModal}
                    handleCheck={handleCheck}
                    setCheckAll={setCheckAll}
                    checkAll={checkAll}
                handleCheckAll={handleCheckAll}
                setClientToSendMail={setClientToSendMail}
                setIsEmail={setIsEmail}
              />
              <div className="smaller__table">
                {rows?.map((row, index) => (
                  <MobileTable key={index}>
                    <div className="small__dashboard--table">
                      <div className="smallTable__right">
                        <input
                          type="checkbox"
                          className="check"
                          checked={row.isChecked}
                          onChange={() => handleCheck(row?.clientId)}
                        />
                        <h3>{row?.firstName + " " + row?.lastName}</h3>
                        <p className="date--deet">
                          {dateFormat(row.renewalDate, "MMM dd, yyyy hh:mm a")}
                        </p>
                        <p className="deets">
                          {" "}
                          <span
                            className={`status ${
                              row?.renewalStatus === "Expired"
                                ? "status-inactive"
                                : row?.renewalStatus === "Today"
                                ? "status-inactive"
                                : "pending"
                            }`}
                          >
                            <span>
                              <Icon
                                id={
                                  row?.renewalStatus === "Expired"
                                    ? "expiredCircle"
                                    : "pendingCircle"
                                }
                                width="12"
                                height="12"
                              />
                            </span>
                            {row?.renewalStatus}
                          </span>
                        </p>
                      </div>
                      <div className="smallTable__left">
                        <p className="amount">Notify via email</p>
                        <div className="mailContainer">
                          <span className="mail">
                            <Icon
                              id="blue-mail"
                              width="24"
                              height="24"
                              onClick={() => handleMailClickHover(row)}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </MobileTable>
                ))}
              </div>
              </>
              )
              : (
                <div className="w-full py-5">
                  <ActiveBenefits
                          icon={NoList}
                          text={`No Members found under this branch.`}
                          centerHeight={false}
                        />
                </div>
                
              )}
            </div>
          </div>
        </div>
      </div>
      {checkedCount?.length > 0 && (
        <div className="member__footer">
          <div className="member__footer--container">
            <div className="__left">
              <p className="selected">
                <span>{checkedCount?.length}</span>
                Selected
              </p>
              <p className="deselect" onClick={() => unCheckBoxes()}>
                Deselect
              </p>
            </div>
            <div className="__right">
              <p>
                Send renewal notification via:{" "}
                <span
                  onClick={() => {
                    setShowWarningModal(true);
                    setIsEmail(true);
                  }}
                >
                  <Icon id="whiteMail" width="20" height="20" /> Email
                </span>
                <span
                  onClick={() => {
                    setShowWarningModal(true);
                    setIsEmail(false);
                  }}
                >
                  <Icon id="whitePhone" width="20" height="20" /> SMS
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      <RenewNotificationModal
        renewalNotificationModal={renewalNotificationModal}
        setRenewalNotificationModal={setRenewalNotificationModal}
        handleRenew={handleRenew}
        loading={isLoading}
        notificationPricing={notificationPricing}
        isEmail={isEmail}
      />

      {showWarningModal && (
        <WarningNotificationModal
          setRenewalNotificationModal={setRenewalNotificationModal}
          setShowWarningModal={setShowWarningModal}
          showWarningModal={showWarningModal}
          checkedList={checkedCount}
          isEmail={isEmail}
        />
      )}
    </>
  );
};

export default Membership;
