import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Icon from "../Icon";
import Button from "../Button";
const WarningNotificationModal = ({
  setShowWarningModal,
  showWarningModal,
  checkedList,
  isEmail,
  setRenewalNotificationModal,
}) => {
  const [missingList, setMissingList] = useState([]);
  useEffect(() => {
    const missing = checkedList.filter((item) => {
      if (isEmail) {
        return !item.email;
      } else {
        return !item.phone;
      }
    });
    setMissingList(missing);
  }, [checkedList, isEmail]);

  return (
    <Modal
      handleClose={() => {
        setShowWarningModal(false);
      }}
      isHeader={false}
      show={showWarningModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalBig={false}
      containerClassName=" warningModal"
    >
      <span>
        <Icon id={"waarning"} height={"64"} width={"64"} />
      </span>
      <h4>Oops, Emails not Available.</h4>
      <div className="warning__list">
        <div className="warning__list--header">
          <p>Missing {isEmail ? "Email(s)" : "Phone Number(s)"}</p>
        </div>
        <div className="warning__list--body">
          <ul>
            {missingList.map((item, index) => (
              <li>
                {index + 1}. {item?.firstName + " " + item?.lastName}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className="__text">
        {missingList?.length} client(s) will not get emails.
      </p>
      <p className="__text-btm">
        Would you like to continue with available emails?
      </p>
      <div className="btn--container">
        <Button
          variant="secondary"
          type="submit"
          className="mt-6"
          block
          size={"md"}
          onClick={() => {
            setShowWarningModal(false);
          }}
        >
          no, Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="mt-6"
          block
          size={"md"}
          onClick={() => {
            setRenewalNotificationModal(true);
            setShowWarningModal(false);
          }}
        >
          {"Yes, Continue"}
        </Button>
      </div>
    </Modal>
  );
};

export default WarningNotificationModal;
