import React from "react";
import Table from "./Table";
import Button from "../Button";
import Icon from "../Icon";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/authHelpers";

const MembersTable = ({
  data,
  setOpenChangeMemberRole,
  setSelctedMembers,
  setShowDeleteModal,
}) => {
  const {user } = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;
  
  const columns = hasPermission(userRole,"ADMIN") ? ["Name", "Contact", "User Role", "Action", ""] : ["Name", "Contact", "User Role"];

  const openChangeSideModal = (row) => {
    setSelctedMembers(row);
    setOpenChangeMemberRole(true);
  };
  const openDeleteModal = (row) => {
    console.log(row, ":row");
    setSelctedMembers(row);
    setShowDeleteModal(true);
  };
  console.log(data, "data");
  return (
    <Table columns={columns}>
      {data?.map((row, index) => (
        <tr className="pointer grey-bg" key={index}>
          <td className="memberName">
            <span className="memberName__icon">
              {row?.firstName?.charAt(0) + row?.lastName?.charAt(0)}
            </span>
            <span className="memberName__name">
              {row?.firstName + " " + row?.lastName}
            </span>
          </td>
          <td style={{ textTransform: "lowercase" }}> {row.contact}</td>
          <td>
            <span
              className={`status ${
                row?.userRole === "SUPER ADMIN"
                  ? "member-SA"
                  : row?.userRole === "ADMIN"
                  ? "member-A"
                  : row?.userRole === "MEMBER"
                  ? "member-M"
                  : row?.userRole === "OPERATOR"
                  ? "member-O"
                  : row?.userRole === "BRANCH MANAGER"
                  ? "member-M"
                  : "pending"
              }`}
            >
              {row?.userRole}
            </span>
          </td>
          {hasPermission(userRole,"ADMIN") && (
          <td>
            {/* <span
              className={`status ${
                row?.userRole === "SUPER_ADMIN"
                  ? "member-SA"
                  : row?.userRole === "ADMIN"
                  ? "member-A"
                  : row?.userRole === "MEMBER"
                  ? "member-M"
                  : row?.userRole === "OPERATOR"
                  ? "member-O"
                  : "pending"
              }`}
            >
              {row?.userRole}
            </span> */}
            <div>
              <Button
                variant="secondary"
                type="submit"
                className="change"
                disabled={row?.userRole === "SUPER ADMIN"}
                size={"sm"}
                onClick={() => openChangeSideModal(row)}
              >
                <div className="flex flex-row items-center gap-[4px]">
                  <Icon id="smPerson" width={16} height={16} />
                  <span className="text-xs underline">{"Change Role"}</span>
                </div>
              </Button>
            </div>
          </td>
          )}
          {hasPermission(userRole,"ADMIN") && (
          <td>
            <div>
              <Button
                variant="secondary"
                type="submit"
                className="change"
                size={"sm"}
                disabled={row?.userRole === "SUPER ADMIN"}
                onClick={() => openDeleteModal(row)}
              >
                <div className="flex flex-row items-center gap-[4px]">
                  <Icon id="removePerson" width={16} height={16} />
                  <span className="text-xs underline">{"Remove member"}</span>
                </div>
              </Button>
            </div>
          </td>
          )}
        </tr>
      ))}
    </Table>
  );
};

export default MembersTable;
