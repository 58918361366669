import React, { useEffect, useState } from "react";
import SideModal from "./SideModal";
import PhoneInput from "./forms/PhoneInput";
import { useFormik } from "formik";
import Icon from "./Icon";
import { useDispatch, useSelector } from "react-redux";
import { editTeamMember, getProviderRoles } from "../features/user/userSlice";

const ChangeMemberRole = ({
  openChangeMemberRole,
  setOpenChangeMemberRole,
  selctedMembers,
}) => {
  const [phoneDetails, setPhoneDetails] = useState({});
  const [openDropdown, setOpenDropdown] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]); // Stores selected item IDs
  const [selectedNames, setSelectedNames] = useState([]);
  const { providerBranch, providerRoles } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const { handleChange, values, submitForm, setFieldValue } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: "",
    },

    onSubmit: (values) => {
      console.log(values);
      console.log(phoneDetails);
      dispatch(
        editTeamMember({
          id: selctedMembers?.id,
          role: Number(values.roles),
          branchIds: checkedItems,
        })
      );
      setOpenChangeMemberRole(false);
    },
  });

  const handleCheckboxChange = (item) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(item.id)) {
        // Remove from selection
        setSelectedNames((prevNames) =>
          prevNames.filter((name) => name !== item.name)
        );
        return prevCheckedItems.filter((id) => id !== item.id);
      } else {
        // If single selection mode is active, allow only one selection
        if (values?.roles === "10" || values?.roles === "14") {
          setSelectedNames([item.name]);
          return [item.id];
        } else {
          // Add to selection normally
          setSelectedNames((prevNames) => [...prevNames, item.name]);
          return [...prevCheckedItems, item.id];
        }
      }
    });
  };
  useEffect(() => {
    setCheckedItems([]);
    setSelectedNames([]);
  }, [values?.roles]);

  useEffect(() => {
    setFieldValue("firstName", selctedMembers?.firstName);
    setFieldValue("lastName", selctedMembers?.lastName);
    setFieldValue("email", selctedMembers?.contact);
    setFieldValue("roles", selctedMembers?.roles);
  }, []);

  useEffect(() => {
    if (!providerRoles.length) {
      dispatch(getProviderRoles());
    }
  }, [dispatch, providerRoles]);

  return (
    <SideModal
      openSideModal={openChangeMemberRole}
      setOpenSideModal={setOpenChangeMemberRole}
      headerText={"Change Role"}
      handleClick={() => submitForm()}
      btnText={"Assign Role"}
    >
      <div className="overflow-scroll h-[76.5vh]">
        <div className="border boder-1 border-[#D3D3D399] rounded-xl overflow-hidden">
          <div className="border boder-b-1 border-b-[#D3D3D399] bg-[#F5F5F5] px-6 py-3 ">
            <h4 className="text-base font-semibold text-[#344054]">
              Member Information
            </h4>
            <p className="text-sm font-normal text-[#5C6368] mt-[6px]">
              An email will be sent to the Member with their role and a link to
              create a password.
            </p>
          </div>
          <div className="p-6">
            <div className="flex flex-col md:space-x-[20px] md:flex-row">
              <div className="flex flex-col w-full md:w-[50%] ">
                <label
                  htmlFor="firstName"
                  className="text-sm font-medium text-[#344054]"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  placeholder="First name"
                  value={values.firstName}
                  onChange={handleChange}
                  type="text"
                  className="h-[56px] border border-1 border-[#D3D3D399] rounded-lg px-6 mt-[6px]"
                />
              </div>
              <div className="flex flex-col w-full mt-[20px] md:mt-0 md:w-[50%]">
                <label
                  htmlFor="lastName"
                  className="text-sm font-medium text-[#344054]"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  value={values.lastName}
                  onChange={handleChange}
                  className="h-[56px] border border-1 border-[#D3D3D399] rounded-lg px-6 mt-[6px]"
                />
              </div>
            </div>
            <div className="flex flex-col md:space-x-[20px]  md:flex-row md:mt-4">
              <div className="flex flex-col w-full mt-[20px] md:mt-0 md:w-[50%]">
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-[#344054]"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  className="h-[56px] border border-1 border-[#D3D3D399] rounded-lg px-6 mt-[6px]"
                />
              </div>
              <div className="flex flex-col w-full mt-[20px] md:mt-0 md:w-[50%]">
                <label
                  htmlFor="roles"
                  className="text-sm font-medium text-[#344054]"
                >
                  Phone Number (Optional)
                </label>
                <div className="phoneInput mt-[6px]">
                  <PhoneInput setPhoneDetails={setPhoneDetails} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border boder-1 border-[#D3D3D399] rounded-xl overflow-hidden mt-[30px]">
          <div className="border boder-b-1 border-b-[#D3D3D399] bg-[#F5F5F5] px-6 py-3 ">
            <h4 className="text-base font-semibold text-[#344054]">
              Assign Role
            </h4>
            <p className="text-sm font-normal text-[#5C6368] mt-[6px]">
              Select a role to define the Member's access.
            </p>
          </div>
          <div className="p-6">
            <div className="flex flex-col w-[100%]">
              <label
                htmlFor="roles"
                className="text-sm font-medium text-[#344054]"
              >
                Roles
              </label>
              <select
                id="roles"
                value={values.roles}
                onChange={handleChange}
                name="roles"
                className="h-[56px] border border-1 border-[#D3D3D399] rounded-lg px-6 mt-[6px]"
              >
                <option value="" disabled>
                  Select a role...
                </option>
                {providerRoles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name.split('_').map(word => 
                      word.charAt(0) + word.slice(1).toLowerCase()
                    ).join(' ')}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="border boder-1 border-[#D3D3D399] rounded-xl mt-[30px]">
          <div className="border boder-b-1 border-b-[#D3D3D399] bg-[#F5F5F5] px-6 py-3 ">
            <h4 className="text-base font-semibold text-[#344054]">
              Select Branch
            </h4>
            <p className="text-sm font-normal text-[#5C6368] mt-[6px]">
              Choose the branch the Member will be assigned to.
            </p>
          </div>
          <div className="p-6">
            <div className="flex flex-col w-[100%]">
              <label
                htmlFor="branch"
                className="text-sm font-medium text-[#344054]"
              >
                Branch
              </label>
              {/* <select
                id="branch"
                value={values.branch}
                name="branch"
                onChange={handleChange}
                className="h-[56px] border border-1 border-[#D3D3D399] rounded-lg px-6 mt-[6px]"
              >
                <option value="" disabled>
                  Select a branch...
                </option>
                <option value="10">Operator</option>
                <option value="14">Branch Manager</option>
                <option value="11">Regional Manager</option>
                <option value="9">Admin</option>
                <option value="1">Super Admin</option>
              </select> */}
              <>
                <div
                  className="selectbox"
                  onMouseOver={() => setOpenDropdown(true)}
                  onMouseLeave={() => setOpenDropdown(false)}
                >
                  <div className="selectbox__field">
                    <p
                      className={`${checkedItems?.length > 0 ? "active" : ""}`}
                    >
                      {selectedNames?.length > 0
                        ? selectedNames.map((item) => (
                            <>
                              {item}
                              <span>,</span>{" "}
                            </>
                          ))
                        : "Select Member Branch"}{" "}
                    </p>
                    <Icon id="dropdown" width="16" height="11" />
                  </div>
                  {openDropdown && (
                    <div className="selectbox__dropdown">
                      <ul className="select">
                        {providerBranch?.branches?.length > 0 &&
                          providerBranch?.branches?.map((item, index) => (
                            // <li key={index}>
                            //   <input
                            //     type="checkbox"
                            //     id={index}
                            //     name={item?.id}
                            //     value={item?.id}
                            //     onChange={() => handleCheckboxChange(item)}
                            //     checked={checkedItems.includes(item.name)}
                            //   />
                            //   <label htmlFor={item?.id}>{item?.name}</label>
                            // </li>
                            <li key={item.id}>
                              <input
                                type="checkbox"
                                id={`checkbox-${item.id}`}
                                name={item.name}
                                value={item.id}
                                onChange={() => handleCheckboxChange(item)}
                                checked={checkedItems.includes(item.id)}
                              />
                              <label htmlFor={`checkbox-${item.id}`}>
                                {item.name}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </>
            </div>
            {/* <div className="bg-[#F5F5F5] mt-[12px] px-6 py-4">
              <h4 className="text-sm font-semibold text-[#5C6368] mb-1">
                Role Permission
              </h4>
              <ul>
                <li className="py-1 text-xs font-normal text-[#5C6368] flex items-center ">
                  <span className="block w-[10px] h-[10px] bg-[#CED4DA] rounded-full mr-2"></span>
                  Add / Edit Client
                </li>
                <li className="py-1 text-xs font-normal text-[#5C6368] flex items-center ">
                  <span className="block w-[10px] h-[10px] bg-[#CED4DA] rounded-full mr-2"></span>
                  Delete Client
                </li>
                <li className="py-1 text-xs font-normal text-[#5C6368] flex items-center ">
                  <span className="block w-[10px] h-[10px] bg-[#CED4DA] rounded-full mr-2"></span>
                  Renew / Change plan
                </li>
                <li className="py-1 text-xs font-normal text-[#5C6368] flex items-center ">
                  <span className="block w-[10px] h-[10px] bg-[#CED4DA] rounded-full mr-2"></span>
                  Log Pass
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </SideModal>
  );
};

export default ChangeMemberRole;
