import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import SearchAddContainer from "../../components/SearchAddContainer";
import ClientsTable from "../../components/table/ClientsTable";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/PageLoader";
import { dateFormat, fmtCurrency, paginate } from "../../utils/helper";
import {
  changeNavtitle,
  fetchProviderBenefit,
} from "../../features/user/userSlice";
import Pagination from "../../components/table/Pagination";
import AddClientsFIleModal from "../../components/modal/AddClientsFIleModal";
import { AddClientsModal } from "../../components/modal/AddClientsModal";
import AddClientSuccess from "../../components/modal/AddClientsSucces";
import MobileTable from "../../components/table/MobileTable";
import { useNavigate } from "react-router-dom";
import { getProviderSubscribers } from "../../features/clients/clientSlice";
import NoList from "../../assets/svgs/nolist.svg";
import ActiveBenefits from "../../components/ActiveBenefits";

const Clients = () => {
  const [userSearch, setUserSearch] = useState("");
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedMemberlist, setPaginatedMemberlist] = useState([]);
  const [page, setPage] = useState(0);
  const { isLoading, submitClientsManuallySuccess } = useSelector(
    (store) => store.user
  );
  const { clients } = useSelector((store) => store.client);
  const [openAddClientsCsvModal, setOpenAddClientsCsvModal] = useState(false);
  const [closeClientModal, setCloseClientModal] = useState(false);
  const [csvSelected, setCsvSelected] = useState("");
  const [manualClientUploadModalSuccess, setManualClientUploadModalSuccess] =
    useState(false);
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [userFilteredByStatus, setUserFilteredByStatus] = useState([]);
  const [storedData, setStoredData] = useState({});
  const dispatch = useDispatch();

  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  console.log(storedData);
  useEffect(() => {
    getFromLocalStorage();
  }, [storedData]);
  useEffect(() => {
    dispatch(getProviderSubscribers());
    dispatch(changeNavtitle("Clients"));
  }, [storedData?.id]);

  useEffect(() => {
    dispatch(fetchProviderBenefit());
  }, [dispatch, storedData?.id]);

  const clientListFilter = userFilteredByStatus?.filter((user) => {
    if (
      user?.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
      user?.lastName?.toLowerCase().includes(userSearch.toLowerCase())
    ) {
      return user;
    }
  });
  useEffect(() => {
    if (paginatedMemberlist) {
      setPaginatedList(paginatedMemberlist[page]);
    }
  }, [paginatedMemberlist, page]);
  useEffect(() => {
    if (clientListFilter) {
      setPaginatedMemberlist(paginate(clientListFilter));
    }
  }, [clientListFilter?.length]);

  useEffect(() => {
    setManualClientUploadModalSuccess(submitClientsManuallySuccess);
    if (submitClientsManuallySuccess) {
      setCloseClientModal(false);
    }
  }, [submitClientsManuallySuccess]);
  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter?.value === "" || selectedFilter?.value === "All") {
        setUserFilteredByStatus(clients?.data?.clients?.content);
        return;
      } else {
        const numOfPlanStatus = clients?.data?.clients?.content?.filter(
          (user) => {
            if (
              user?.clientType?.toLowerCase() ===
              selectedFilter?.value.toLowerCase()
            ) {
              return user;
            }
          }
        );
        setUserFilteredByStatus(numOfPlanStatus);
      }
    } else {
      setUserFilteredByStatus(clients?.data?.clients?.content);
    }
  }, [clients?.data?.clients?.content, selectedFilter]);

  const getActualPrice = (row) => {
    const benefit = row?.benefit?.priceOptions.find(
      (item) => item?.duration === row?.benefitDuration
    );
    return benefit?.price;
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="clients">
          <div className="clients__header">
            <div className="btns">
              <Button
                variant="secondary"
                type="submit"
                block
                //   disabled={isLoading}
                onClick={() => setCloseClientModal(true)}
              >
                <span>+</span>
                Add New Clients
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="group"
                block
                onClick={() => setOpenAddClientsCsvModal(true)}
                //   disabled={isLoading}
              >
                <span className="group-hover:translate-y-1 transition-all duration-300">
                  <Icon id={"downloadBlack"} height={"24"} width={"24"} />
                </span>
                IMPORT CLIENTS
              </Button>
            </div>
          </div>
          <div className="table__header">
            <h4>Clients</h4>
            <SearchAddContainer
              changeInput={(e) => setUserSearch(e.target.value)}
              filterOptions={[
                { name: "All", value: "All" },
                { name: "Provider", value: "Provider" },
                { name: "Flancer", value: "flance" },
              ]}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          </div>
          <div className="clients__table">
            <div className="table-container">
              {paginatedList?.length > 0 ? (
                <>
                  <ClientsTable data={paginatedList} />
                  <div className="smaller__table">
                    {paginatedList.map((user, index) => (
                      <MobileTable key={index}>
                        <div
                          onClick={() =>
                            navigate(`/clients/${user?.id}?clientType=${user?.clientType}`)
                          }
                          className="w-full h-full flex flex-row items-center justify-between cursor-pointer"
                        >
                          <div className="flex w-full h-full flex-row items-center justify-between">
                            <div className="flex flex-col items-center justify-center gap-[6px]">
                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                <span
                                  className={`w-[31px] h-[31px] rounded-full flex justify-center items-center ${
                                    user?.clientType === "PROVIDER"
                                      ? "bg-[#F5F5F5]"
                                      : "bg-[#FF8788]"
                                  }`}
                                >
                                  <Icon
                                    id={
                                      user?.clientType === "PROVIDER"
                                        ? "user-gray"
                                        : "user-red"
                                    }
                                    width="24"
                                    height="24"
                                  />
                                </span>
                                <div className="flex flex-col items-start justify-start gap-[6px]">
                                  <span className="font-[500] text-[12px] text-[#000B14]">
                                    {(user?.firstName ? user?.firstName : "-") +
                                      " " +
                                      (user?.lastName ? user?.lastName : "-")}
                                  </span>
                                  <span className="font-[400] text-[10px] text-[#5C6368]">
                                    {fmtCurrency(getActualPrice(user) || 0)}
                                  </span>
                                </div>
                              </div>

                              <span className="font-[400] text-[10px] text-[#5C6368]">
                                Expiry Date{" "}
                                {dateFormat(
                                  user.renewalDate,
                                  "MMM dd, yyyy hh:mm a"
                                )}
                              </span>
                            </div>
                            <div className="flex flex-row items-center justify-center gap-[12px]">
                              <div className="flex flex-col items-center justify-center gap-[12px]">
                                <span className="font-[600] text-[12px] text-[#000B14]">
                                  {user?.benefit?.benefitName
                                    ? user?.benefit?.benefitName
                                    : "--"}
                                </span>
                                <div
                                  className={`flex flex-row items-center justify-center px-[12px] py-[6px] rounded-[20px] ${
                                    user?.status == "ACTIVE"
                                      ? "bg-[#EBF7EE]"
                                      : "bg-[#F5F5F5]"
                                  }`}
                                >
                                  <span
                                    className={`font-[500] text-[10px] tracking-[1%] ${
                                      user?.status == "ACTIVE"
                                        ? "text-[#236F00]"
                                        : "text-[#5C6368]"
                                    }`}
                                  >
                                    {user?.status}
                                  </span>
                                </div>
                              </div>

                              <Icon id="arrow-right-slim" width="8" height="13" />
                            </div>
                          </div>
                        </div>
                      </MobileTable>
                    ))}
                  </div>
                  <Pagination
                    itemList={paginatedMemberlist}
                    page={page}
                    setPage={setPage}
                  />
                </>
              ) : (
                <div className="w-full py-5">
                  <ActiveBenefits
                          icon={NoList}
                          text={`No clients found under this branch.`}
                          centerHeight={false}
                        />
                </div>
              )}
            </div>
          </div>
          {openAddClientsCsvModal && (
            <AddClientsFIleModal
              openAddClientsCsvModal={openAddClientsCsvModal}
              setOpenAddClientsCsvModal={setOpenAddClientsCsvModal}
              setCsvSelected={setCsvSelected}
              csvSelected={csvSelected}
            />
          )}

          {closeClientModal && (
            <AddClientsModal
              closeClientModal={closeClientModal}
              setCloseClientModal={setCloseClientModal}
            />
          )}
          <AddClientSuccess
            successModal={manualClientUploadModalSuccess}
            iconID={"checkSuccess"}
            subtext={`You have successfully added a client. `}
            title={`Congratulations!`}
            iconWidth={64}
            iconHeight={64}
            setManualClientUploadModalSuccess={
              setManualClientUploadModalSuccess
            }
            clients={true}
            setCloseClientModal={setCloseClientModal}
          />
        </div>
      )}
    </>
  );
};

export default Clients;
