import React from "react";
import Modal from "./Modal";
import Button from "../Button";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteTeamMember } from "../../features/user/userSlice";

const DeleteMemberModal = ({
  setShowDeleteModal,
  showDeleteModal,
  selctedMembers,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(selctedMembers?.id);
  return (
    <Modal
      handleClose={() => {
        setShowDeleteModal(false);
      }}
      isHeader={false}
      show={showDeleteModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalSmall={true}
      containerClassName="deltePlans"
    >
      <span>
        <Icon id={"waarning"} height={"64"} width={"64"} />
      </span>
      <h4 className="mt-4">Remove Member</h4>
      <p className="mt-2 co-gray">
        This action is permanent and cannot be undone.
      </p>
      <div className="btn--container--small">
        <Button
          variant="secondary"
          type="submit"
          className="mt-4"
          block
          size={"md"}
          onClick={() => {
            setShowDeleteModal(false);
          }}
        >
          no, Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="mt-4"
          block
          size={"md"}
          onClick={() => {
            dispatch(
              deleteTeamMember({
                id: selctedMembers?.id,
              })
            ).then((res) => {
              if (res.type === "user/deleteTeamMember/fulfilled") {
                setShowDeleteModal(false);
              }
            });
          }}
        >
          {"Yes, Remove Member"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteMemberModal;
