import React, { useEffect, useState } from "react";
import DashboardTable from "../../components/table/DashboardTable";
import MobileTable from "../../components/table/MobileTable";
import Pagination from "../../components/table/Pagination";
import ActiveBenefits from "../../components/ActiveBenefits";
import transactionLoading from "../../assets/svgs/noTransaction.svg";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat, fmtCurrency, paginate, formatDate } from "../../utils/helper";
import {
  getProviderCredit,
  getProviderCreditTransactions,
} from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import FundAmountModal from "../../components/modal/FundAmountModal";
import Spinner from "../../components/PageLoader";
import { TransactionDateFIlterOptions } from "../../utils/helper";
import DatePicker from "react-date-picker";
import toast from "react-hot-toast";


const Transactions = () => {
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedTransactionlist, setPaginatedTransactionlist] = useState([]);
  const [page, setPage] = useState(0);
  const [openFundModal, setOpenFundModal] = useState(false);
  const navigate = useNavigate();
  const { isLoading, providerCredit, transactions } = useSelector(
    (store) => store.user
  );
  const [
    openTransactionDateFIlterDropdown,
    setOpenTransactionDateFIlterDropdown,
  ] = useState(false);
  const [
    selectedTransactionDateFIlterDropdownItem,
    setSelectedTransactionDateFIlterDropdownItem,
  ] = useState(
    JSON.parse(localStorage.getItem("transactionDateFilter")) ||
      TransactionDateFIlterOptions[0]
  );

  const [customStartDate, setCustomStartDate] = useState(
    localStorage.getItem("customStartDate") || ""
  );
  const [customEndDate, setCustomEndDate] = useState(
    localStorage.getItem("customEndDate") || ""
  );

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem(
      "transactionDateFilter",
      JSON.stringify(selectedTransactionDateFIlterDropdownItem)
    );
  }, [selectedTransactionDateFIlterDropdownItem]);

  useEffect(() => {
    localStorage.setItem("customStartDate", customStartDate);
  }, [customStartDate]);

  useEffect(() => {
    localStorage.setItem("customEndDate", customEndDate);
  }, [customEndDate]);

  useEffect(() => {
    const getDateRange = () => {
      const today = new Date();
      let startDate, endDate;
      switch (selectedTransactionDateFIlterDropdownItem?.value) {
        case "TODAY":
          startDate = endDate = formatDate(today);
          break;
        case "PAST_3_DAYS":
          startDate = formatDate(new Date(today.setDate(today.getDate() - 3)));
          endDate = formatDate(new Date());
          break;
        case "THIS_WEEK":
          const monday = new Date(
            today.setDate(today.getDate() - today.getDay() + 1)
          );
          startDate = formatDate(monday);
          endDate = formatDate(new Date(monday.setDate(monday.getDate() + 6)));
          break;
        case "THIS_MONTH":
          startDate = formatDate(
            new Date(today.getFullYear(), today.getMonth(), 1)
          );
          endDate = formatDate(
            new Date(today.getFullYear(), today.getMonth() + 1, 0)
          );
          break;
        case "THIS_YEAR":
          startDate = `${today.getFullYear()}-01-01`;
          endDate = `${today.getFullYear()}-12-31`;
          break;
        case "CUSTOM":
          // Format the date objects to YYYY-MM-DD strings
          startDate = customStartDate;
          endDate = customEndDate;
          // Validate that end date is not earlier than start date
          if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
            toast.error("End date cannot be earlier than start date");
          }
          break;
        default:
          startDate = endDate = undefined;
          setCustomEndDate("");
          setCustomStartDate("");
          localStorage.removeItem("customStartDate");
          localStorage.removeItem("customEndDate");
      }
      return { startDate, endDate };
    };

    const { startDate, endDate } = getDateRange();
    dispatch(
      getProviderCreditTransactions({
        startDate: startDate ?? "",
        endDate: endDate ?? "",
      })
    );
    dispatch(getProviderCredit());
  }, [
    selectedTransactionDateFIlterDropdownItem,
    customStartDate,
    customEndDate,
    dispatch,
  ]);
  useEffect(() => {
    if (paginatedTransactionlist) {
      setPaginatedList(paginatedTransactionlist[page]);
    }
  }, [paginatedTransactionlist, page]);
  useEffect(() => {
    if (transactions?.content) {
      setPaginatedTransactionlist(paginate(transactions?.content));
    }
  }, [transactions?.content?.length]);
  return (
    <>
      <div className="transactions">
        <p
          className="prev-btn"
          onClick={() => {
            navigate("/");
          }}
        >
          <Icon id="back" width="14" height="14" className="back-icon" />
          Back to Home
        </p>
        <div className="transactions__header">
          <div className="transactions__header--left">
            <span>
              <Icon id="bigWallet" width="60" height="60" />
            </span>
            <div className="balance">
              <p>BalanCe</p>
              <h4>{fmtCurrency(providerCredit?.balance)}</h4>
            </div>
          </div>
          <div className="transactions__header--right">
            <Button variant={"primary"} onClick={() => setOpenFundModal(true)}>
              <span className="nairaIcon">
                <Icon id="naira+" width="24" height="24" />
              </span>{" "}
              Fund Account
            </Button>
          </div>
        </div>

        <div className="transactions__body">
          <div className="transactions__body__header">
            <h2>Transaction History</h2>
            <div
              className="transactions__body__header--filter"
              onClick={() =>
                setOpenTransactionDateFIlterDropdown(
                  !openTransactionDateFIlterDropdown
                )
              }
              onMouseLeave={() => setOpenTransactionDateFIlterDropdown(false)}
            >
              <div className="filter__dropdown--container">
                <Icon id="calender" width="16" height="16" />
                <div className="filter__dropdown">
                  {selectedTransactionDateFIlterDropdownItem?.value !==
                  "CUSTOM" ? (
                    <p>
                      {selectedTransactionDateFIlterDropdownItem?.value ===
                      "ALL"
                        ? "Date"
                        : selectedTransactionDateFIlterDropdownItem?.name}
                    </p>
                  ) : (
                    <div
                      className="custom__date--container"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="date-picker-inputs">
                        <span>FROM</span>
                        <DatePicker
                          prev2Label={null}
                          next2Label={null}
                          prevLabel={
                            <Icon id="calendar-prev" width="12" height="12" />
                          }
                          nextLabel={
                            <Icon id="calendar-next" width="12" height="12" />
                          }
                          value={
                            customStartDate ? new Date(customStartDate) : null
                          }
                          onChange={(date) => setCustomStartDate(formatDate(date))}
                        />
                      </div>

                      <Icon id="dash-small" width="6" height="2" />
                      <div className="date-picker-inputs">
                        <span>TO</span>
                        <DatePicker
                          prev2Label={null}
                          next2Label={null}
                          prevLabel={
                            <Icon id="calendar-prev" width="12" height="12" />
                          }
                          nextLabel={
                            <Icon id="calendar-next" width="12" height="12" />
                          }
                          value={customEndDate ? new Date(customEndDate) : null}
                          onChange={(date) => setCustomEndDate(formatDate(date))}
                        />
                      </div>
                    </div>
                  )}
                  <Icon id="arrow-down-small" width="16" height="16" />
                </div>

                {openTransactionDateFIlterDropdown && (
                  <div className="filter__dropdown--list">
                    <ul>
                      {TransactionDateFIlterOptions.map((item, index) => (
                        <li
                          onClick={() => {
                            setSelectedTransactionDateFIlterDropdownItem(item);
                            setOpenTransactionDateFIlterDropdown(false);
                          }}
                        >
                          <p>{item?.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {transactions?.content?.length >= 1 ? (
                <>
                  {" "}
                  <div className="overview__table">
                    <div className="table-container">
                      <DashboardTable data={paginatedList} />
                      <div className="smaller__table">
                        {paginatedList?.map((user, index) => (
                          <MobileTable>
                            <div className="small__dashboard--table">
                              <div className="smallTable__right">
                                <h3>{user?.transactionType}</h3>
                                <p className="deets">{user?.transactionId}</p>
                                <p className="date--deet">
                                  {dateFormat(
                                    user.dateCreated,
                                    "MMM dd, yyyy hh:mm a"
                                  )}
                                </p>
                              </div>
                              <div className="smallTable__left">
                                <p className="amount">
                                  {fmtCurrency(user?.amount)}
                                </p>
                                <span
                                  className={`status ${
                                    user?.operationType === "CR"
                                      ? "employee-active"
                                      : user?.operationType === "DR" ||
                                        user?.subscriptionStatus === "CANCELLED"
                                      ? "status-inactive"
                                      : "pending"
                                  }`}
                                >
                                  {user?.operationType === "CR"
                                    ? "Credit"
                                    : "Debit"}
                                </span>
                              </div>
                            </div>
                          </MobileTable>
                        ))}
                      </div>
                      <Pagination
                        itemList={paginatedTransactionlist}
                        page={page}
                        setPage={setPage}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="overview__table">
                  <div className="table-container ">
                    <DashboardTable />
                    <div className="mt-10 mb-10">
                      <ActiveBenefits
                        icon={transactionLoading}
                        text={
                          <span>
                            No transaction yet.
                            <br />
                            Once you start processing transactions, they'll
                            appear here.
                          </span>
                        }
                        centerHeight={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {openFundModal && (
        <FundAmountModal
          setOpenFundModal={setOpenFundModal}
          openFundModal={openFundModal}
        />
      )}
    </>
  );
};

export default Transactions;
