import React from "react";
import Modal from "./Modal";
import Icon from "../Icon";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProviderBenefit,
  fetchProviderBenefit,
} from "../../features/user/userSlice";

const DeletePlanModal = ({ setShowDeleteModal, showDeleteModal, plan }) => {
  const { submitClientsManuallyLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  return (
    <Modal
      handleClose={() => {
        setShowDeleteModal(false);
      }}
      isHeader={false}
      show={showDeleteModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalSmall={true}
      containerClassName="deltePlans"
    >
      <span>
        <Icon id={"waarning"} height={"64"} width={"64"} />
      </span>
      <h4 className="mt-4">Delete Plan</h4>
      <p className="mt-2 co-gray">Deleting this plan will permanently remove it. This action cannot be undone.</p>
      <div className="btn--container--small">
        <Button
          variant="secondary"
          type="submit"
          className="mt-4"
          block
          size={"md"}
          onClick={() => {
            setShowDeleteModal(false);
          }}
        >
          no, Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="mt-4"
          block
          size={"md"}
          onClick={() => {
            dispatch(deleteProviderBenefit(plan?.id)).then((res) => {
              if (res.type === "user/deleteProviderBenefit/fulfilled") {
                setShowDeleteModal(false);
                dispatch(fetchProviderBenefit());
              }
            });
          }}
        >
          {submitClientsManuallyLoading ? "Deleting..." : "Yes, Delete Plan"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeletePlanModal;
