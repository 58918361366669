import React, { useEffect, useState } from "react";
import { Input, Select } from "../forms";
import Button from "../Button";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { submitClientManually } from "../../features/user/userSlice";
import { addFixedDuration, dateFormat } from "../../utils/helper";
import * as Yup from "yup";
import BigModal from "./BigModal";
import PhoneInput from "../forms/PhoneInput";

export const AddClientsModal = ({ closeClientModal, setCloseClientModal }) => {
  const { providerBenefit, submitClientsManuallyLoading } = useSelector(
    (store) => store.user
  );

  const [priceOption, setPriceOption] = useState([]);
  const [benefitDurationPrice, setBenefitDurationPrice] = useState("");
  const [phoneDetails, setPhoneDetails] = useState({});

  const dispatch = useDispatch();
  const [storedData, setStoredData] = useState({});
  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  useEffect(() => {
    getFromLocalStorage();
  }, [storedData]);

  const schema = Yup.object({
    firstName: Yup.string().required("first name field is required"),
    benefitPaymentDate: Yup.string().required("Payment date is required"),
    benefitId: Yup.string().required("Plan is required"),
    benefitDuration: Yup.string().required("Duration is required"),
  });

  const { handleChange, values, submitForm, errors, touched } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      benefitId: "",
      benefitDuration: "",
      hasPersonalTrainer: false,
      benefitPaymentDate: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        submitClientManually({
          firstName: values?.firstName,
          lastName: values?.lastName ? values?.lastName : null,
          email: values?.email ? values?.email : null,
          phone: phoneDetails?.phoneNumber,
          dateOfBirth: values?.dateOfBirth ? values?.dateOfBirth : null,
          benefitId: values?.benefitId,
          benefitDuration: values?.benefitDuration,
          hasPersonalTrainer: false,
          benefitPaymentDate: values?.benefitPaymentDate,
          callingCode: phoneDetails?.country?.dialCode,
          providerBranchId: storedData?.id,
        })
      );
    },
  });

  useEffect(() => {
    if (values?.benefitId) {
      const benefit = providerBenefit.find(
        (item) => Number(item?.id) === Number(values?.benefitId)
      );

      if (benefit) {
        setPriceOption(benefit.priceOptions);
      } else {
        setPriceOption([]);
      }
    }
  }, [values.benefitId]);

  useEffect(() => {
    if (values?.benefitDuration) {
      const benefit = priceOption.find(
        (item) => item?.duration === values?.benefitDuration
      );

      if (benefit) {
        setBenefitDurationPrice(benefit?.price);
      } else {
        setBenefitDurationPrice("");
      }
    }
  }, [values.benefitDuration, values.benefitId]);

  return (
    <BigModal
      handleClose={() => {
        setCloseClientModal(false);
      }}
      show={closeClientModal}
      containerClassName="addClients--modal"
      isModalOpen={closeClientModal}
      isVisible={closeClientModal}
      headerTitle={"Add Clients Manually"}
    >
      <div className="inputgroup">
        <Input
          type="text"
          id="firstName"
          label="First name"
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          placeholder="First name"
          error={
            touched.firstName && errors.firstName ? (
              <small className="error">{errors.firstName}</small>
            ) : null
          }
        />
        <Input
          type="text"
          id="lastName"
          label="Last name (Optional)"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          placeholder="Last name (Optional)"
        />
      </div>
      <div className="clients__phone">
        <div className="phoneInput">
          <PhoneInput setPhoneDetails={setPhoneDetails} />
        </div>
      </div>
      <div className="inputgroup">
        <Input
          type="email"
          id="email"
          name="email"
          label="Email Address (optional)"
          value={values.email}
          onChange={handleChange}
          placeholder="Email Address (optional)"
        />
        <Input
          id="dateOfBirth"
          type="date"
          placeholder="Date of birth"
          label="Birthday (optional)"
          name="dateOfBirth"
          value={values.dateOfBirth}
          onChange={handleChange}
          max={new Date().toISOString().split("T")[0]}
          isDate={true}
        />
      </div>
      <div className="input-group">
        <div className="address--deets">
          <p>Select Plan</p>
          <Select
            options={providerBenefit?.length ? providerBenefit.map((item) => ({
              name: item?.benefitName,
              value: item?.id,
            })) : []}
            id="benefitId"
            name="benefitId"
            placeholder={providerBenefit?.length ? "Select Plan" : "No plans available"}
            onChange={handleChange}
            value={values.benefitId}
            error={
              touched.benefitId && errors.benefitId ? (
                <small className="error">{errors.benefitId}</small>
              ) : null
            }
            disabled={!providerBenefit?.length}
          />
        </div>
        <div className="address--deets">
          <p>Select Plan Duration</p>
          <Select
            options={priceOption.map((item) => ({
              name: item?.price ? item?.duration : null,
              value: item?.price ? item?.duration : null,
            }))}
            id="benefitDuration"
            name="benefitDuration"
            placeholder="Duration"
            onChange={handleChange}
            value={values.benefitDuration}
            error={
              touched.benefitDuration && errors.benefitDuration ? (
                <small className="error">{errors.benefitDuration}</small>
              ) : null
            }
          />
        </div>
        <div className="address--deets">
          <p>Plan Price</p>
          <Input
            id="planPrice"
            name="planPrice"
            placeHolder="Plan Price"
            isFloatingLabel={false}
            disabled={true}
            // onChange={handleChange}
            value={benefitDurationPrice}
          />
        </div>
      </div>
      <div className="input-time">
        <div className="address--deets">
          <p>Date Paid</p>
          <Input
            type="date"
            id="benefitPaymentDate"
            placeHolder="MM/DD/YYYY"
            isFloatingLabel={false}
            name="benefitPaymentDate"
            maxDate={new Date()}
            isDate={true}
            value={values.benefitPaymentDate}
            onChange={handleChange}
            error={
              touched.benefitPaymentDate && errors.benefitPaymentDate ? (
                <small className="error">{errors.benefitPaymentDate}</small>
              ) : null
            }
          />
        </div>
        <div className="address--deets">
          <p>Renewal Date</p>
          <Input
            id="dob"
            type="date"
            placeHolder="jul, 12 2024"
            isFloatingLabel={false}
            name="dob"
            maxDate={new Date()}
            isDate={true}
            value={
              values.benefitPaymentDate && values.benefitDuration
                ? dateFormat(
                    addFixedDuration(
                      values.benefitPaymentDate,
                      values.benefitDuration
                    ),
                    "yyyy-MM-dd"
                  ) || "yyyy-MM-dd"
                : "mm/dd/yyyy"
            }
            disabled={true}
          />
        </div>
      </div>
      <div className="__btn">
        <Button
          block={true}
          variant={"primary"}
          className={""}
          disabled={submitClientsManuallyLoading}
          onClick={() => submitForm()}
        >
          {submitClientsManuallyLoading ? "Submitting..." : "Submit"}{" "}
        </Button>
      </div>
    </BigModal>
  );
};
