import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Input } from "./forms";
import Icon from "./Icon";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderBranch,
  submitPlanDetails,
} from "../features/user/userSlice";

const PlanPricing = () => {
  const { isLoading, providerBranch } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const [plans, setPlans] = useState([
    {
      benefitName: "",
      priceOptions: [
        { duration: "DAILY", price: "" },
        { duration: "WEEKLY", price: "" },
        { duration: "BI_WEEKLY", price: "" },
        { duration: "MONTHLY", price: "" },
        { duration: "QUARTERLY", price: "" },
        { duration: "ANNUALLY", price: "" },
        { duration: "BIANNUALLY", price: "" },
      ],
      includesPersonalTrainer: false,
      hasGuestPass: false,
      membershipFee: false,
      publishOnFlance: true,
      benefitCategory: "GYM",
    },
  ]);

  useEffect(() => {
    dispatch(getProviderBranch());
  }, []);

  // Function to handle input changes for plan name
  const handlebenefitNameChange = (index, value, inputName) => {
    const updatedPlans = [...plans];
    updatedPlans[index][inputName] = value;
    setPlans(updatedPlans);
  };

  // Function to handle price changes for each plan
  const handlePriceChange = (planIndex, optionIndex, value) => {
    const updatedPlans = [...plans];
    updatedPlans[planIndex].priceOptions[optionIndex].price = value;
    setPlans(updatedPlans);
  };

  // Function to handle checkbox changes for each plan
  const handleCheckboxChange = (index, field) => {
    const updatedPlans = [...plans];
    updatedPlans[index][field] = !updatedPlans[index][field];
    setPlans(updatedPlans);
  };

  const addNewPlan = () => {
    setPlans([
      ...plans,
      {
        benefitName: "",
        priceOptions: [
          { duration: "DAILY", price: "" },
          { duration: "WEEKLY", price: "" },
          { duration: "BI_WEEKLY", price: "" },
          { duration: "MONTHLY", price: "" },
          { duration: "QUARTERLY", price: "" },
          { duration: "ANNUALLY", price: "" },
          { duration: "BIANNUALLY", price: "" },
        ],
        includesPersonalTrainer: false,
        hasGuestPass: false,
        membershipFee: false,
        publishOnFlance: true,
        benefitCategory: "GYM",
      },
    ]);
  };

  return (
    <div className="planPricing">
      <div className="businessInfo--header">
        <div className="businessInfo--header__deets">
          <h1>Add Price Plans</h1>
          <p>Set up your pricing structure</p>
        </div>
      </div>
      <div className="planPicing--header__info">
        {plans.map((plan, index) => (
          <div className="mt-6">
            <Accordion preExpanded={["a"]} allowZeroExpanded>
              <AccordionItem uuid="a">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3 className="main--text">
                      {`${index + 1}. ${
                        plan?.benefitName ? plan?.benefitName : "Plan"
                      }`}{" "}
                    </h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Enter Plan name</p>
                  <div className="add--input">
                    <Input
                      id={`benefitName-${index}`}
                      name={`benefitName-${index}`}
                      placeHolder="(e.g., Monthly, Couple, Family, Weekend, Day Pass, Weekly)"
                      isFloatingLabel={false}
                      value={plan.benefitName}
                      onChange={(e) =>
                        handlebenefitNameChange(
                          index,
                          e.target.value,
                          "benefitName"
                        )
                      }
                    />
                  </div>
                  <div className="price__duration">
                    <p>Select Plan Price and Duration</p>
                    <div className="price__duration--lists">
                      {plan.priceOptions.map((option, optionIndex) => (
                        <div className="list" key={option.duration}>
                          <input
                            id={option.duration}
                            type="number"
                            value={option.price}
                            onChange={(e) =>
                              handlePriceChange(
                                index,
                                optionIndex,
                                e.target.value
                              )
                            }
                            placeHolder="₦ 0000 000"
                            onKeyDown={(e) =>
                              ["e", "E", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <span className={`${option.price ? "active" : ""}`}>
                            {option.duration === "BI_WEEKLY"
                              ? "Bi-weekly"
                              : option.duration.toLowerCase()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="plan--deets">
                    <label>
                      <input
                        type="checkbox"
                        checked={plan.includesPersonalTrainer}
                        onChange={() =>
                          handleCheckboxChange(index, "includesPersonalTrainer")
                        }
                      />
                      Includes personal trainer
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={plan.hasGuestPass}
                        onChange={() =>
                          handleCheckboxChange(index, "hasGuestPass")
                        }
                      />
                      Allow guest pass
                    </label>
                  </div>
                  {/* <div className="plan--deets">
                    <div className="showOnFlance">
                      <div className="toggle--container">
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={`membershipFee-${index}`}
                            id={`membershipFee-${index}`}
                            checked={plan.membershipFee}
                            onChange={() =>
                              handleCheckboxChange(index, "membershipFee")
                            }
                          />
                          <label
                            className="label"
                            htmlFor={`membershipFee-${index}`}
                          >
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                        Allow Membership Fee
                      </div>
                    </div>
                  </div> */}
                  <div className="plan--deets">
                    <div className="showOnFlance">
                      <div className="toggle--container">
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={`publishOnFlance-${index}`}
                            id={`publishOnFlance-${index}`}
                            checked={plan.publishOnFlance}
                            onChange={() =>
                              handleCheckboxChange(index, "publishOnFlance")
                            }
                          />
                          <label
                            className="label"
                            htmlFor={`publishOnFlance-${index}`}
                          >
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                        Show plan on Flance
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        ))}

        <div className="plan--deets__btn">
          <button onClick={addNewPlan}>+ Add New plan</button>
        </div>
      </div>
      <Button
        block={true}
        variant={"primary"}
        className={"mt-10"}
        disabled={isLoading ? true : false}
        onClick={() =>
          dispatch(
            submitPlanDetails({
              benefits: plans.map((plan) => ({
                benefitName: plan.benefitName,
                priceOptions: plan?.priceOptions.filter(
                  (option) => option.price !== ""
                ),
                includesPersonalTrainer: plan.includesPersonalTrainer,
                hasGuestPass: plan.hasGuestPass,
                membershipFee: plan.membershipFee,
                publishOnFlance: plan.publishOnFlance,
                benefitCategory: "GYM",
              })),
              providerBranchIds: providerBranch?.branches.map((ids) => ids.id),
            })
          )
        }
      >
        Continue
      </Button>
    </div>
  );
};

export default PlanPricing;
