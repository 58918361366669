import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { Input } from "../forms";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderCreditTransactions,
  submitPassCode,
} from "../../features/user/userSlice";
import { getClientVisits } from "../../features/clients/clientSlice";

const PassModal = ({
  openFlancePassModal,
  setOpenFlancePassModal,
  containerClassName,
  storedData,
  setOpenVerificationStatus,
  setVerificationStatus,
  setVerifiedClientName,
  setVerificationErrorMessage
}) => {
  const { passDetails, submitClientsManuallyLoading } = useSelector(
    (store) => store.user
  );

  // const getBranch = localStorage.getItem("branch");

  // console.log(typeof getBranch);

  // const storedData =
  // getBranch && getBranch === String ? JSON.parse(getBranch) : null;

  const [passInfo, setPassInfo] = useState("");
  const dispatch = useDispatch();
  const submitCode = () => {
    dispatch(
      submitPassCode({
        accessCode: passInfo,
        visitTimestamp: new Date(),
        providerBranchId: storedData?.id,
      })
    ).then((res) => {
      setOpenFlancePassModal(false);
      setOpenVerificationStatus(true);
      
      
      if (res?.type === "user/submitPassCode/fulfilled") {
        setVerificationStatus("ok");
        // Format the name as "Firstname .L"
        const firstName = res.payload?.data?.firstName || "";
        const lastInitial = res.payload?.data?.lastName?.charAt(0) || "";
        const formattedName = `${firstName} .${lastInitial}`;
        setVerifiedClientName(formattedName);
        dispatch(getProviderCreditTransactions());
        dispatch(getClientVisits({ branch: storedData?.id }));
      } else {
        // Handle different error cases based on the response
        if (res?.payload === "Oops, This Passcode has Expired") {
          setVerificationStatus("expired");
        }
        else if (res?.payload === "Oops, This Passcode is Invalid") {
          setVerificationStatus("invalid");
        }
         else {
          setVerificationStatus("other");
          setVerificationErrorMessage(res?.payload)
        }
      }
      setPassInfo("")
    });
  };
  return (
    <Modal
      handleClose={() => {
        setOpenFlancePassModal(false);
      }}
      title={"Enter passcode"}
      show={openFlancePassModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalBig={false}
      containerClassName="addClients--modal"
    >
      <p className="tx-c">Please enter your passcode.</p>
      <Input
        className={"mt-6"}
        isFloatingLabel={false}
        value={passInfo}
        onChange={(e) => setPassInfo(e.target.value)}
      />
      <Button
        variant="primary"
        type="submit"
        className="mt-10"
        block
        // disabled={isLoading}
        onClick={() => submitCode()}
      >
        {submitClientsManuallyLoading ? "Verifying..." : "Verify code"}
      </Button>
    </Modal>
  );
};

export default PassModal;
