import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNavtitle,
  fetchProviderBenefit,
  getProviderSubscribersById,
  updateProviderClients,
} from "../../features/user/userSlice";
import Icon from "../../components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { Input } from "../../components/forms";
import {
  addFixedDuration,
  dateFormat,
  fmtCurrency,
  useQuery,
} from "../../utils/helper";
import Spinner from "../../components/PageLoader";
import SuccessModal from "../../components/modal/SuccessModal";
import RenewPlanModal from "../../components/modal/RenewPlanModal";
import ChangePlanModal from "../../components/modal/ChangePlanModal";
import { getSingleClientActivity } from "../../features/clients/clientSlice";
import skeletonLoading from "../../assets/svgs/nolist.svg";
import ActiveBenefits from "../../components/ActiveBenefits";
import EditClientDetailsModal from "../../components/modal/EditClientDetailsModal";
import DeleteClientModal from "../../components/modal/DeleteClientModal";
import { hasPermission } from "../../utils/authHelpers";
const ClientInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useParams();
  const query = useQuery();
  const clientType = query.get("clientType") || false;
  const [editActive, setEditActive] = useState(false);
  useEffect(() => {
    dispatch(fetchProviderBenefit());
  }, [dispatch]);
  const { client, submitClientsManuallyLoading, isLoading, providerBenefit } =
    useSelector((store) => store.user);
  const { user } = useSelector((store) => store.user);
  const userRole = user?.data?.roleName;

  const { singleClientActivity } = useSelector((store) => store.client);

  useEffect(() => {
    dispatch(changeNavtitle("Client Information"));
    dispatch(getProviderSubscribersById({ id: route?.id, clientType }));
    dispatch(getSingleClientActivity({ id: route?.id, clientType }));
  }, []);

  const [planId, setPlanId] = useState(null);
  const [planIdSelect, setPlanIdSelect] = useState(null);
  const [duration, setDuration] = useState([]);
  const [selectDuration, setSelectDuration] = useState();
  const [durationPice, setDurationPrice] = useState("");
  const [newDate, setNewDate] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
  const providerData = JSON.parse(localStorage.getItem("userProvider"));
  // const [personalInfo, setPersonalInfo] = useState({});
  const [storedData, setStoredData] = useState({});
  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  useEffect(() => {
    getFromLocalStorage();
  }, [storedData?.id]);

  const updateUserDetails = (e) => {
    e.preventDefault();
    dispatch(
      updateProviderClients({
        clientId: Number(route?.id),
        newBenefitId: planIdSelect,
        benefitDuration: selectDuration,
        clientType,
        benefitPaymentDate: newDate
          ? dateFormat(newDate, "yyyy-MM-dd")
          : dateFormat(client?.benefitPaymentDate, "yyyy-MM-dd"),
        providerBranchId: storedData?.id,
        providerId: providerData?.data?.providerId,
        oldBenefitId: client?.benefit?.id,
      })
    ).then((res) => {
      if (res?.type === "user/updateProviderClients/fulfilled") {
        setOpenChangeModal(false);
        setSuccessModal(true);
      }
    });
  };

  useEffect(() => {
    setPlanId(client?.benefit?.id);
  }, [client, route.id]);

  useEffect(() => {
    setSelectDuration(client?.benefitDuration);
  }, [client, route.id]);

  useEffect(() => {
    if (planId) {
      setPlanIdSelect(planId);
    }
  }, [planId, route.id]);

  useEffect(() => {
    if (planIdSelect) {
      const benefit = providerBenefit.find(
        (item) => Number(item?.id) === Number(planIdSelect)
      );

      if (benefit) {
        setDuration(benefit.priceOptions);
      } else {
        setDuration([]);
      }
    }
  }, [planIdSelect, route.id]);

  useEffect(() => {
    if (selectDuration) {
      setDurationPrice("");
      const benefit = duration.find(
        (item) => item?.duration === selectDuration
      );
      console.log(benefit);
      if (benefit?.price) {
        console.log("hii");
        setDurationPrice(benefit?.price);
      } else {
        setDurationPrice("");
      }
    }
  }, [selectDuration, planIdSelect, client]);

  console.log(selectDuration);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          <div className="clients">
            <div className="clients--info__header">
              <p
                className="prev-btn"
                onClick={() => {
                  navigate("/clients");
                }}
              >
                <Icon id="back" width="14" height="14" className="back-icon" />
                Back to Clients
              </p>
              <div className="act-btns">
                {clientType === "PROVIDER" &&
                  hasPermission(userRole, "ADMIN") && (
                    <div className="edit--btn">
                      <Button
                        variant="secondary"
                        type="submit"
                        className=""
                        block
                        size={"md"}
                        onClick={() => setEditActive(true)}
                      >
                        <span className="penIcon">
                          <Icon id={"pen"} height={"16"} width={"16"} />
                        </span>
                        Edit
                      </Button>
                    </div>
                  )}
                {clientType === "PROVIDER" &&
                  hasPermission(userRole, "ADMIN") && (
                    <div className="edit--btn">
                      <Button
                        variant="secondary"
                        type="submit"
                        className=""
                        block
                        size={"md"}
                        onClick={() => setShowDeleteClientModal(true)}
                      >
                        <span className="penIcon">
                          <Icon id={"trash"} height={"16"} width={"16"} />
                        </span>
                        Delete
                      </Button>
                    </div>
                  )}
                <div className="change--btn">
                  <Button
                    variant="primary"
                    type="submit"
                    className=""
                    block
                    size={"md"}
                    onClick={() => setOpenChangeModal(true)}
                  >
                    <span className="penIcon">
                      <Icon id={"change"} height={"16"} width={"16"} />
                    </span>
                    Change Plan
                  </Button>
                </div>
                <div className="change--btn">
                  <Button
                    variant="primary"
                    type="submit"
                    className=""
                    block
                    size={"md"}
                    onClick={() => setOpenRenewModal(true)}
                  >
                    <span className="penIcon">
                      <Icon id={"cycle"} height={"16"} width={"16"} />
                    </span>
                    Renew Plan
                  </Button>
                </div>
              </div>
            </div>
            <div className="clients--info__body">
              <div className="deets">
                <div className="deets--header">
                  <h4>Personal Information</h4>
                </div>
                <div className="deets--body">
                  <div className="client--image">
                    <div className="img--container">
                      {client?.image ? (
                        <>
                          <img src={client?.image} alt="client" />
                        </>
                      ) : (
                        <span>
                          <p>
                            {client?.firstName?.charAt(0) +
                              client?.lastName?.charAt(0)}
                          </p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form">
                    <div className="inputgroup">
                      <div className="address--deets">
                        <p>
                          <span>
                            <Icon id={"profile"} height={"16"} width={"16"} />
                          </span>{" "}
                          Full Name
                        </p>
                        <h4>{client?.firstName + " " + client?.lastName}</h4>
                      </div>

                      {!editActive ? (
                        <div className="address--deets">
                          <p>
                            <span>
                              <Icon id={"mail"} height={"16"} width={"16"} />
                            </span>
                            {""}
                            EMAIL
                          </p>
                          <h4> {client?.email ? client?.email : "Nill"}</h4>
                        </div>
                      ) : (
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="email"
                          label={"Emall"}
                          // disabled
                          value={client?.email ? client?.email : "Nill"}
                        />
                      )}
                    </div>
                    <div className="inputgroup">
                      {!editActive ? (
                        <div className="address--deets">
                          <p>
                            {" "}
                            <span>
                              <Icon id={"phone"} height={"16"} width={"16"} />
                            </span>
                            {""} PHONE NUMBER
                          </p>
                          <h4> {client?.phone ? client?.phone : "Nill"}</h4>
                        </div>
                      ) : (
                        <Input
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          label={"Phone Number"}
                          // disabled
                          value={client?.phone}
                          type="tel"
                        />
                      )}
                      {!editActive ? (
                        <div className="address--deets">
                          <p>
                            <span>
                              <Icon
                                id={"calender"}
                                height={"14"}
                                width={"16"}
                              />
                            </span>
                            {""}DOB
                          </p>
                          <h4>
                            {client?.dateOfBirth ? client?.dateOfBirt : "Nil"}
                          </h4>
                        </div>
                      ) : (
                        <Input
                          id="dateOfBirth"
                          placeholder="Date of birth"
                          label="Date of birth"
                          name="dateOfBirth"
                          // disabled
                          type="date"
                          value={
                            client?.dateOfBirth ? client?.dateOfBirth : "Nil"
                          }
                          // onChange={handleChange}
                          maxDate={new Date()}
                          isDate={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="deets">
                <div className="deets--header">
                  <h4>Plan Details</h4>
                </div>
                <div className="deets--body">
                  <div className="input-group">
                    <div className="address--deets">
                      <p>
                        {" "}
                        <span>
                          <Icon id={"smallPlan"} height={"16"} width={"16"} />
                        </span>
                        {""} Plan
                      </p>
                      <h4>
                        {" "}
                        {client?.benefit?.benefitName
                          ? client?.benefit?.benefitName
                          : "--"}
                      </h4>
                    </div>
                    <div className="address--deets">
                      <p>
                        {" "}
                        <span>
                          <Icon id={"calender"} height={"16"} width={"16"} />
                        </span>
                        {""} Plan duration
                      </p>
                      <h4> {selectDuration ? selectDuration : "--"}</h4>
                    </div>
                    <div className="address--deets">
                      <p>
                        <span>
                          <Icon id={"naira"} height={"16"} width={"16"} />
                        </span>
                        {""} Price
                      </p>
                      <h4>
                        {" "}
                        {durationPice ? fmtCurrency(durationPice) : "--"}
                      </h4>
                    </div>
                    <div className="address--deets">
                      <p>
                        {" "}
                        <span>
                          <Icon id={"calender"} height={"16"} width={"16"} />
                        </span>
                        {""} Date paid
                      </p>
                      <h4>
                        {" "}
                        {newDate
                          ? newDate
                          : dateFormat(
                              client?.benefitPaymentDate,
                              "MMM/dd/yyyy m:s"
                            )}
                      </h4>
                    </div>
                    <div className="address--deets">
                      <p>
                        {" "}
                        <span>
                          <Icon id={"refresh"} height={"16"} width={"16"} />
                        </span>
                        {""} Renewal Date
                      </p>
                      <h4>
                        {(newDate || client?.benefitPaymentDate) &&
                        selectDuration
                          ? dateFormat(
                              addFixedDuration(
                                newDate ? newDate : client?.benefitPaymentDate,
                                selectDuration
                              ),
                              "MMM/dd/yyyy"
                            ) || "mm/dd/yyyy"
                          : "--"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="deets">
                <div className="deets--header">
                  <h4>Client Activities</h4>
                </div>
                <div className="deets--body">
                  <div className="activity--body">
                    {singleClientActivity?.length > 0 ? (
                      <>
                        {singleClientActivity.map((activity) => (
                          <div className="activites__table" key={activity?.id}>
                            <div className="activites__table--left">
                              <h4>{activity?.activity}</h4>
                              <h5>{activity?.duration || "--"}</h5>
                            </div>
                            <div className="activites__table--right">
                              <div className="amount">
                                <span className="ststus success">success</span>
                                <p>{fmtCurrency(activity?.amount)}</p>
                              </div>
                              <p>
                                {dateFormat(
                                  activity?.dateCreated,
                                  "MMM dd, yyyy"
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className=" empty-state">
                        <ActiveBenefits
                          icon={skeletonLoading}
                          text={`No activity yet! Once actions are taken, you'll see them here.`}
                          centerHeight={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SuccessModal
            successModal={successModal}
            iconID={"checkSuccess"}
            buttonText={"Continue"}
            subtext={`Your changes have been saved successfully `}
            title={`Changes Saved`}
            iconWidth={64}
            iconHeight={64}
            buttonAction={() => {
              setSuccessModal(false);
              dispatch(
                getProviderSubscribersById({ id: route?.id, clientType })
              );
              dispatch(getSingleClientActivity({ id: route?.id, clientType }));
            }}
          />
        </>
      )}

      {openRenewModal && (
        <RenewPlanModal
          setOpenRenewModal={setOpenRenewModal}
          openRenewModal={openRenewModal}
          client={client}
          selectDuration={selectDuration}
          durationPrice={durationPice}
          id={route?.id}
        />
      )}
      {openChangeModal && (
        <ChangePlanModal
          setOpenChangeModal={setOpenChangeModal}
          openChangeModal={openChangeModal}
          newDate={newDate}
          providerBenefit={providerBenefit}
          editActive={editActive}
          setSelectDuration={setSelectDuration}
          setPlanId={setPlanId}
          planIdSelect={planIdSelect}
          duration={duration}
          selectDuration={selectDuration}
          durationPice={durationPice}
          client={client}
          setNewDate={setNewDate}
          updateUserDetails={updateUserDetails}
          setDurationPrice={setDurationPrice}
          isloading={submitClientsManuallyLoading}
        />
      )}
      {editActive && (
        <EditClientDetailsModal
          setEditActive={setEditActive}
          editActive={editActive}
          client={client}
          clientType={clientType}
          id={route?.id}
        />
      )}
      {showDeleteClientModal && (
        <DeleteClientModal
          showDeleteModal={showDeleteClientModal}
          setShowDeleteModal={setShowDeleteClientModal}
          client={client}
        />
      )}
    </>
  );
};

export default ClientInfo;
